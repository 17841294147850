import React from 'react';
import cn from 'classnames';

import { OvalSpinner } from '../OvalSpinner';

import styles from './Button.styles.scss';

import { useKeycloak } from '@react-keycloak/web';
import { THEMES } from '../../../constants/common';

const VARIANTS = {
  contained: styles.contained,
  outlined: styles.outlined,
};

const Button = ({
  className,
  loading,
  onClick,
  variant,
  children,
  loaderHeight,
  loaderWidth,
  loaderClassName,
  ...restProps
}) => {
  const { keycloak } = useKeycloak();

  return (
    <button
      className={cn(styles.button, VARIANTS[variant], className)}
      {...restProps}
      onClick={onClick}
    >
      {loading && (
        <OvalSpinner
          height={loaderHeight}
          width={loaderWidth}
          color={
            variant === 'contained'
              ? '#F0F0F0'
              : THEMES[keycloak?.realm]?.primary
          }
          className={loaderClassName}
        />
      )}
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  variant: 'contained',
};

export default React.memo(Button);
