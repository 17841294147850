export const FOOTER_LINKS = [
  {
    title: { en: 'Latest News', ar: 'آخر المستجدات' },
    links: [
      {
        label: {
          en: 'Income Tax Exemption Controls and Conditions for Economic Diversification Sectors',
          ar: 'Income Tax Exemption Controls and Conditions for Economic Diversification Sectors',
        },
        url: '',
      },
      {
        label: { en: 'Tax Stamp scheme', ar: 'Tax Stamp scheme' },
        url: '',
      },
      {
        label: {
          en: 'Announcement regarding update the communication data',
          ar: 'Announcement regarding update the communication data',
        },
        url: '',
      },
      {
        label: {
          en: 'Workshops on Value-Added Tax and Corporate Income Tax in Salalah Free Zone',
          ar: 'Workshops on Value-Added Tax and Corporate Income Tax in Salalah Free Zone',
        },
        url: '',
      },
      {
        label: {
          en: 'Taxpayer Services conducts outreach field visit',
          ar: 'Taxpayer Services conducts outreach field visit',
        },
        url: '',
      },
    ],
  },
  {
    title: { en: 'Latest Events', ar: 'آخر الأنشطة' },
    links: [
      {
        label: {
          en: 'Country-by-Country Reporting (CbCR) Workshop',
          ar: 'Country-by-Country Reporting (CbCR) Workshop',
        },
        url: '',
      },
      {
        label: {
          en: 'Common Reporting Standard (CRS) Workshop',
          ar: 'Common Reporting Standard (CRS) Workshop',
        },
        url: '',
      },
    ],
  },
  {
    title: { en: 'Tax Portal', ar: 'منصة الضريبة' },
    links: [
      {
        label: { en: 'Tax Laws', ar: 'Tax Laws' },
        url: '',
      },
      {
        label: { en: 'Excise Tax', ar: 'Excise Tax' },
        url: '',
      },
      {
        label: { en: 'VAT', ar: 'VAT' },
        url: '',
      },
      {
        label: { en: 'Double Tax Agreements', ar: 'Double Tax Agreements' },
        url: '',
      },
      {
        label: { en: 'Community Surveys', ar: 'Community Surveys' },
        url: '',
      },
    ],
  },
  {
    title: { en: 'Contact Us', ar: 'تواصل معنا' },
    links: [
      {
        label: { en: 'Contact us', ar: 'تواصل معنا' },
        icon: 'contactUs',
        url: 'tel:+000000000',
      },
      {
        label: { en: 'info@example.com', ar: 'info@example.com' },
        icon: 'mail',
        url: 'mailto:info@example.com',
      },
      {
        label: {
          en: 'Example, Example, avenue',
          ar: 'Example, Example, avenue',
        },
        icon: 'location',
        url: '',
      },
    ],
    type: 'social',
  },
];
