import { initialValues } from './Filters.formConfig';
import moment from 'moment';

export const convertDataToForm = (data) => {
  if (!data || data.length === 0) {
    return initialValues;
  }

  const search = data.find((filter) => filter.type === 'search');
  const invoiceStatus = data.find((filter) => filter.type === 'invoiceStatus');
  const issueDateFrom = data.find((filter) => filter.type === 'issueDateFrom');
  const issueDateTo = data.find((filter) => filter.type === 'issueDateTo');
  const typeOfNote = data.find((filter) => filter.type === 'typeOfNote');
  const invoiceTypeDescription = data.find(
    (filter) => filter.type === 'invoiceTypeDescription',
  );

  return {
    search: search?.value || '',
    invoiceStatus: invoiceStatus?.value || '',
    issueDateFrom: issueDateFrom?.value || '',
    issueDateTo: issueDateTo?.value || '',
    typeOfNote: typeOfNote?.value || '',
    invoiceTypeDescription: invoiceTypeDescription?.value || '',
  };
};

export const filterData = (filter, originalData) => {
  let filteredData = [...originalData];
  if (filter.issueDateFrom) {
    filteredData = filteredData.filter((ele) =>
      moment(ele.issueDate, 'YYYY-MM-DD hh:mm:ss').isSameOrAfter(
        moment(filter.issueDateFrom, 'DD/MM/YYYY'),
      ),
    );
  }
  if (filter.issueDateTo) {
    filteredData = filteredData.filter((ele) =>
      moment(ele.issueDate, 'YYYY-MM-DD hh:mm:ss').isSameOrBefore(
        moment(filter.issueDateTo, 'DD/MM/YYYY'),
      ),
    );
  }

  if (filter.typeOfNote) {
    filteredData = filteredData.filter(
      (ele) => ele.noteType === filter.typeOfNote,
    );
  }

  if (filter.invoiceType) {
    filteredData = filteredData.filter(
      (ele) => ele.invoiceType === filter.invoiceType,
    );
  }

  if (filter.invoiceStatus) {
    filteredData = filteredData.filter(
      (ele) => ele.invoiceStatus === filter.invoiceStatus,
    );
  }

  if (filter.search) {
    filteredData = filteredData.filter((ele) => {
      return (
        (ele.invoiceType &&
          ele.invoiceType.toLowerCase().indexOf(filter.search.toLowerCase()) !==
            -1) ||
        (ele.invoiceReferenceNumber &&
          ele.invoiceReferenceNumber
            .toLowerCase()
            .indexOf(filter.search.toLowerCase()) !== -1) ||
        (ele.englishBuyerName &&
          ele.englishBuyerName
            .toLowerCase()
            .indexOf(filter.search.toLowerCase()) !== -1) ||
        (ele.grossTotalIncludingVat &&
          ('' + ele.grossTotalIncludingVat).indexOf(
            filter.search.toLowerCase(),
          ) !== -1)
      );
    });
  }

  return filteredData;
};
