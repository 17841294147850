import React, { useState } from 'react';
import {
  CNavbarToggler,
  COffcanvas,
  COffcanvasHeader,
  CCloseButton,
  COffcanvasBody,
  CNavbarNav,
  CNavbar,
} from '@coreui/react';
import styles from './SideBarMenu.styles.scss';
import { useTranslation } from 'react-i18next';

const SideBarMenu = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const { i18n } = useTranslation();

  return (
    <CNavbar colorScheme="light" color="black" className="bg-transparent">
      <CNavbarToggler
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
        onClick={() => setVisible(!visible)}
        className={styles.toggler}
      />
      <COffcanvas
        id="offcanvasNavbar"
        placement={i18n.language === 'en' ? 'end' : 'start'}
        portal={false}
        visible={visible}
        onHide={() => setVisible(false)}
        color="white"
        className={styles.offcanvas}
      >
        <COffcanvasHeader>
          <CCloseButton
            className="text-reset"
            onClick={() => setVisible(false)}
          />
        </COffcanvasHeader>
        <COffcanvasBody>
          <CNavbarNav className={styles.navBarNav}>{children}</CNavbarNav>
        </COffcanvasBody>
      </COffcanvas>
      {/* </> */}
    </CNavbar>
  );
};

export default React.memo(SideBarMenu);
