import React from 'react';
import Section from './Section';
import {
  InputField,
  TextareaField,
  SelectField,
  DatePickerInputField,
} from '../../../../../../components/FormikFields';
import {
  validateItemFields,
  getProductWithTotals,
} from '../../../../../../helpers/invoiceHelpers';
import { v4 as uuid } from 'uuid';
import { defaultItemData } from '../../formConfig/initialValues';
import { useNavigate } from 'react-router-dom';

const SectionContainer = ({ inputs, handleStepSubmit, t, ...props }) => {
  const navigate = useNavigate();

  const getInputType = (type) => {
    switch (type) {
      case 'text': {
        return InputField;
      }
      case 'select': {
        return SelectField;
      }
      case 'datepicker': {
        return DatePickerInputField;
      }
      case 'textarea': {
        return TextareaField;
      }
    }
  };

  const handleAddItemClick = async ({
    values,
    validateForm,
    setTouched,
    setErrors,
    resetForm,
  }) => {
    const hasError = await validateItemFields({
      setTouched,
      validateForm,
      setErrors,
      item: values.tempItem,
      t,
    });

    if (hasError) {
      return;
    }

    values.tempItem.quantity = Number(values.tempItem.quantity).toFixed(3);
    values.tempItem.discountAmount = Number(
      values.tempItem.discountAmount,
    ).toFixed(3);
    values.tempItem.vatAmount = Number(values.tempItem.vatAmount).toFixed(3);

    const currentItem = getProductWithTotals({
      item: values.tempItem,
    });

    currentItem.creating = true;

    resetForm({
      values: {
        ...values,
        items: [...values.items, currentItem],
        tempItem: { id: uuid(), ...defaultItemData() },
      },
    });
  };

  const getComponentProps = (input) => {
    const {
      onChange,
      onBlur,
      options,
      disabled,
      id,
      name,
      disabledDays,
      maxlength,
    } = input;
    const componentProps = {
      disabled,
      id,
      name,
    };
    if (onChange) {
      componentProps.onChange = onChange;
    }
    if (onBlur) {
      componentProps.onBlur = onBlur;
    }
    if (options) {
      componentProps.options = options;
    }
    if (disabledDays) {
      componentProps.disabledDays = disabledDays;
    }
    if (maxlength) {
      componentProps.maxlength = maxlength;
    }
    return componentProps;
  };

  const handleCancelClick = () => navigate(-1);

  return (
    <Section
      handleFormSubmit={handleStepSubmit}
      getInputType={getInputType}
      inputs={inputs}
      onAddItemClick={handleAddItemClick}
      t={t}
      getComponentProps={getComponentProps}
      onCancelClick={handleCancelClick}
      {...props}
    />
  );
};

export default React.memo(SectionContainer);
