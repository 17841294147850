import React from 'react';
import cn from 'classnames';
import styles from './OtpInput.styles.scss';
import { Button } from '../../../../components/shared/Button';
import { randomKey } from '../../../../helpers/common';

const OtpInput = ({
  currentCodeLength,
  value,
  className,
  copyText,
  t,
  multipleOTPs,
}) => (
  <div className={cn(styles.container, !multipleOTPs ? 'w-75' : '')}>
    <div className={cn(styles.input, className)}>
      {Array.from(Array(currentCodeLength)).map((_, i) => (
        <input
          disabled
          key={randomKey()}
          className={cn(
            multipleOTPs && styles.multipleOTPsField,
            styles.field,
            'p-1',
          )}
          type="number"
          value={value[i]}
        />
      ))}
      {multipleOTPs && (
        <Button
          className={cn(styles.copyBtn, 'mx-4')}
          variant={'outlined'}
          onClick={() => copyText(value)}
          id={value + '_copy_btn'}
          // disabled={value[0] === ''}
        >
          {t('copy_code')}
        </Button>
      )}
    </div>
  </div>
);

export default React.memo(OtpInput);
