import React from 'react';
import { MainLayout } from '../../components/MainLayout';
import { PagesHeader } from '../../components/shared/PagesHeader';
import styles from './TaxReturn.styles.scss';
import { ROUTES } from './TaxReturn.config';
import { ReturnsListTable } from './components/ReturnsListTable';

const TaxReturn = ({ t, returns, loading }) => {
  return (
    <MainLayout eInvoicing={true} bannerClassName={styles.banner}>
      <PagesHeader
        withBreadcrumb={true}
        title={t('display_submit_returns')}
        routes={ROUTES}
        titleClassName={styles.title}
        isTaxReturn={true}
      />
      <ReturnsListTable returns={returns} loading={loading} t={t} />
    </MainLayout>
  );
};

export default React.memo(TaxReturn);
