import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({});

export const initialValues = {
  search: '',
  invoiceStatus: '',
  issueDateFrom: '',
  issueDateTo: '',
  typeOfNote: '',
  invoiceType: '',
};

export default {
  initialValues,
  validationSchema,
};
