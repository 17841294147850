import React from 'react';
import styles from './PageTile.styles.scss';
import { Button } from '../shared/Button';
import cn from 'classnames';

const PageTile = ({
  title,
  iconName,
  buttonName,
  handleClick,
  disabled,
  onHover,
  hoverStyles,
  titleClassName,
}) => {
  let image;

  try {
    image = require(`../../assets/png/${iconName}.png`);
  } catch (e) {
    image = null;
  }

  return (
    <div
      className={cn(
        styles.container,
        hoverStyles() ? styles.hoveredContainer : '',
      )}
    >
      <div
        className={cn(
          styles.icon,
          disabled ? styles.disabledIcon : '',
          styles.imageDiv,
        )}
      >
        {/* <Icon name={iconName} /> */}
        {image && <img src={image} />}
      </div>
      <div className={styles.titleDiv}>
        <p
          className={cn(
            styles.title,
            disabled ? styles.disableTitle : '',
            titleClassName,
          )}
        >
          {title}
        </p>
      </div>
      <div className={styles.buttonDiv}>
        <Button
          className={cn(
            styles.button,
            'px-4',
            hoverStyles() ? styles.hoveredBtn : '',
          )}
          onClick={handleClick}
          disabled={disabled}
          onMouseOver={onHover}
          onMouseLeave={onHover}
          id={iconName + '_btn'}
        >
          {buttonName}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(PageTile);
