import React from 'react';
import styles from './NumberOfOTPs.styles.scss';
import cn from 'classnames';
import { MAX_NUMBER_OF_OTPS } from './NumberOfOTPs.config';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/shared/Button';

const NumberOfOTPs = ({ noOfOTPs, onGenerate, onChange, code, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={cn('row mb-5', styles.container)}>
        <p className={cn(styles.title)}>
          {t('otp_generation_page_number_of_otps', { max: MAX_NUMBER_OF_OTPS })}
        </p>
        <div className={cn('row', styles.inputs)}>
          <input
            onChange={onChange}
            className={cn('col-10 py-2', styles.numOfOtpsInput)}
            type="text"
            value={noOfOTPs}
            disabled={!!code[0]}
          />
          {/* <span className={styles.separator} /> */}
          <input
            className={cn('col-2 py-2 px-1', styles.otp)}
            defaultValue={'OTP'}
            type="text"
            disabled
          />
        </div>
        <div className={cn('row mt-4', styles.buttonContainer)}>
          <Button
            onClick={() => onGenerate(noOfOTPs)}
            className={cn(styles.button, 'col-12')}
            disabled={!noOfOTPs || !!code[0] || loading}
            id="generate_otp_btn"
          >
            {t('generate')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default React.memo(NumberOfOTPs);
