export const PAGES = [
  {
    title: 'view_sales_register',
    iconName: 'taxReturn',
    buttonName: 'View',
    redirectUrl: 'sales-register',
  },
  {
    title: 'view_purchase_register',
    iconName: 'taxReturn',
    buttonName: 'View',
    redirectUrl: 'purchase-register',
  },
  {
    title: 'display_submit',
    iconName: 'taxReturn',
    buttonName: 'Display/Submit',
    redirectUrl: 'display-submit-returns',
  },
];

export const ROUTES = [
  {
    label: 'tax_return',
    link: '',
  },
  {
    label: 'Indirect Taxes - Value Added Tax',
    link: '/',
  },
];
