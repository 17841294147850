import React from 'react';
import cn from 'classnames';
import { INPUTS, SALES_REASON_OPTIONS } from './SalesForm.config';
import { Formik, Form } from 'formik';
import { FormikField } from '../../../../../../../components/FormikField';
import { InputField } from '../../../../../../../components/FormikFields';
import {
  PURCHASES_INPUTS,
  PURCHASES_REASON_OPTIONS,
} from '../PurchasesForm/PurchasesForm.config';
import { Button } from '../../../../../../../components/shared/Button';
import { SelectWithInput } from '../SelectWithInput';

const SalesForm = ({
  styles,
  isReduced,
  handleAdjustmentBlur,
  handleAmountBlur,
  handleSubmit,
  values: initialValues,
}) => {
  const allowedOptionsArray = [
    'PROPORTIONAL_DEDUCTION',
    'TIMING_DIFFERENCE',
    'NON_BUSINESS',
  ];

  return (
    <div className={styles.salesFormContainer}>
      <div className={cn(styles.purchaseHeaders, 'row')}>
        <p className="col-2">VAT on sales</p>
        <p className="col-2">Amount (SAR)</p>
        <p className="col-2">Adjustment (SAR)</p>
        <p className="col-2">Reason</p>
        <p className="col-2">VAT Amount (SAR)</p>
      </div>
      <div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form className={cn('row', styles.salesInputs)}>
              {INPUTS(isReduced).map(
                (input, i) =>
                  !input.hide && (
                    <React.Fragment key={i}>
                      <p className={cn(input.bold ? styles.boldHeader : '')}>
                        {input.title}
                      </p>
                      <FormikField
                        name={input.fieldName}
                        component={InputField}
                        componentProps={{
                          onBlur: (e) =>
                            handleAmountBlur({ e, setFieldValue, values }),
                          className: styles.input,
                          disabled: input.disabled,
                        }}
                        controlProps={{
                          className: cn('col-2'),
                        }}
                      />
                      <FormikField
                        name={input.adjustmentName}
                        component={InputField}
                        componentProps={{
                          onBlur: (e) =>
                            handleAdjustmentBlur({ e, setFieldValue, values }),
                          className: styles.input,
                          disabled: input.disabled,
                        }}
                        controlProps={{
                          className: cn('col-2'),
                        }}
                      />
                      {input.reasonName ? (
                        <SelectWithInput
                          styles={styles}
                          allowedOptionsArray={allowedOptionsArray}
                          options={SALES_REASON_OPTIONS}
                          name={input.reasonName}
                          disabled={input.disabled}
                          className={cn('col-2')}
                        />
                      ) : (
                        <FormikField
                          name="none"
                          component={InputField}
                          componentProps={{
                            disabled: true,
                            className: styles.input,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      )}
                      {input.taxAmountName ? (
                        <FormikField
                          name={input.taxAmountName}
                          component={InputField}
                          componentProps={{
                            disabled: true,
                            className: styles.input,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      ) : input.hideTaxField ? (
                        <input
                          className={cn(
                            styles.input,
                            styles.placeholderInput,
                            'col-2',
                          )}
                          disabled
                        />
                      ) : (
                        <FormikField
                          name={'none'}
                          component={InputField}
                          componentProps={{
                            disabled: true,
                            className: styles.input,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      )}
                    </React.Fragment>
                  ),
              )}
              <div className={cn(styles.purchaseHeaders, 'row')}>
                <p>VAT on purchase</p>
                <p>Amount (SAR)</p>
                <p>Adjustment (SAR)</p>
                <p>Reasons</p>
                <p>VAT Amount (SAR)</p>
              </div>
              {PURCHASES_INPUTS(isReduced).map(
                (input, i) =>
                  !input.hide && (
                    <React.Fragment key={i}>
                      <p className={cn(input.bold ? styles.boldHeader : '')}>
                        {input.title}
                      </p>
                      {input.fieldName ? (
                        <FormikField
                          name={input.fieldName}
                          component={InputField}
                          componentProps={{
                            onBlur: (e) =>
                              handleAmountBlur({ e, setFieldValue, values }),
                            className: styles.input,
                            disabled: input.disabled,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      ) : input.hideValueField ? (
                        <input
                          className={cn(
                            styles.input,
                            styles.placeholderInput,
                            'col-2',
                          )}
                          disabled
                        />
                      ) : (
                        <FormikField
                          name="none"
                          component={InputField}
                          componentProps={{
                            disabled: true,
                            className: styles.input,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      )}
                      {input.adjustmentName ? (
                        <FormikField
                          name={input.adjustmentName}
                          component={InputField}
                          componentProps={{
                            onBlur: (e) =>
                              handleAdjustmentBlur({
                                e,
                                setFieldValue,
                                values,
                              }),
                            className: styles.input,
                            disabled: input.disabled,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      ) : input.hideAdjField ? (
                        <input
                          className={cn(
                            styles.input,
                            styles.placeholderInput,
                            'col-2',
                          )}
                          disabled
                        />
                      ) : (
                        <FormikField
                          name="none"
                          component={InputField}
                          componentProps={{
                            disabled: true,
                            className: styles.input,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      )}
                      {input.reasonName ? (
                        <SelectWithInput
                          styles={styles}
                          allowedOptionsArray={allowedOptionsArray}
                          options={PURCHASES_REASON_OPTIONS}
                          name={input.reasonName}
                          disabled={input.disabled}
                          className={cn('col-2')}
                        />
                      ) : input.hideReasonField ? (
                        <input
                          className={cn(
                            styles.input,
                            styles.placeholderInput,
                            'col-2',
                          )}
                          disabled
                        />
                      ) : (
                        <FormikField
                          name="none"
                          component={InputField}
                          componentProps={{
                            disabled: true,
                            className: styles.input,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      )}
                      {input.taxAmountName ? (
                        <FormikField
                          name={input.taxAmountName}
                          component={InputField}
                          componentProps={{
                            disabled: true,
                            className: styles.input,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      ) : input.hideTaxField ? (
                        <input
                          className={cn(
                            styles.input,
                            styles.placeholderInput,
                            'col-2',
                          )}
                          disabled
                        />
                      ) : (
                        <FormikField
                          name={'none'}
                          component={InputField}
                          componentProps={{
                            disabled: true,
                            className: styles.input,
                          }}
                          controlProps={{
                            className: cn('col-2'),
                          }}
                        />
                      )}
                    </React.Fragment>
                  ),
              )}
              <div className={styles.actionsContainer}>
                <a className={('mx-3', styles.cancelLink)} href="/tax-return">
                  Cancel
                </a>
                <Button
                  className={cn('mx-5 col-2', styles.continueBtn)}
                  type="submit"
                >
                  Step 4
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default React.memo(SalesForm);
