import React from 'react';
import styles from './InitiateNewRegistration.styles.scss';
import { MainLayout } from '../../components/MainLayout';
import { PagesHeader } from '../../components/shared/PagesHeader';
import { useTranslation } from 'react-i18next';
import { ROUTES } from './InitiateNewRegistration.config';
import cn from 'classnames';
import { OvalSpinner } from '../../components/shared/OvalSpinner';
import { NumberOfOTPs } from './components/NumberOfOTPs';
import { OTPs } from './components/OTPs';
import { THEMES } from '../../constants/common';
import { useKeycloak } from '@react-keycloak/web';
import { TilesWrapper } from '../../components/shared/TilesWrapper';

const InitiateNewRegistration = ({
  code,
  onGenerate,
  loading,
  onChange,
  // onHomeClick,
}) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const realm = keycloak?.realm;
  return (
    <MainLayout eInvoicing={true}>
      <PagesHeader
        withBreadcrumb={true}
        title={t('new_registration')}
        routes={ROUTES}
      />
      <TilesWrapper className={styles.wrapper}>
        {/* <Tile className="col-lg-4 col-md-6 col-sm-12 mb-5"> */}
        <div className={cn(styles.inner, 'col-lg-4 col-sm-12')}>
          <p className={cn(styles.title, 'mb-3')}>
            {t('generate_registration_code')}
          </p>
          <NumberOfOTPs
            onChange={onChange}
            onGenerate={onGenerate}
            code={code}
            loading={loading}
          />
        </div>
        {/* </Tile> */}
        {loading ? (
          <OvalSpinner
            color={THEMES[realm]?.primary}
            className={cn('mb-5', styles.loader)}
            width={30}
            height={30}
          />
        ) : (
          code[0] && <OTPs code={code} />
        )}
        {/* <Button onClick={onHomeClick} className={styles.button}>
          {t('common.home')}
        </Button> */}
      </TilesWrapper>
    </MainLayout>
  );
};

export default React.memo(InitiateNewRegistration);
