export const changeTheme = ({
  primary,
  secondary,
  gray,
  background,
  header,
  footer,
}) => {
  document?.documentElement?.style?.setProperty('--color-theme-header', header);
  document?.documentElement?.style?.setProperty('--color-theme-footer', footer);
  document?.documentElement?.style?.setProperty(
    '--color-theme-primary',
    primary,
  );
  document?.documentElement?.style?.setProperty(
    '--color-theme-secondary',
    secondary,
  );
  document?.documentElement?.style?.setProperty('--color-theme-gray', gray);
  document?.documentElement?.style?.setProperty(
    '--color-theme-background',
    background,
  );
};
