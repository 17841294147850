import React from 'react';

import { CFormSelect } from '@coreui/react';
import { SELECT_AN_OPTION_LABEL } from '../../constants/common';

const SelectField = ({ field, form, onChange, ...restProps }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    if (value === SELECT_AN_OPTION_LABEL) {
      form.setFieldValue(field.name, '');
    } else {
      form.setFieldValue(field.name, value);
    }

    if (onChange) {
      onChange(value);
    }
  };

  const handleBlur = () => {
    form.setFieldTouched(field.name, true);
  };

  return (
    <CFormSelect
      {...field}
      {...restProps}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default React.memo(SelectField);
