import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import styles from './DoughnutChart.styles.scss';
import cn from 'classnames';
import { OvalSpinner } from '../shared/OvalSpinner';

const DoughnutChart = ({
  dataToRender,
  options,
  htmlLegendPlugin,
  legendContainerId,
  title,
  loading,
}) => {
  return (
    <div className={cn(styles.container, 'py-5')}>
      <p className={cn(styles.title)}>{title}</p>
      <div className={cn(styles.chart)}>
        {loading ? (
          <OvalSpinner className="chart-loader" width={50} height={50} />
        ) : (
          <>
            <Doughnut
              plugins={[htmlLegendPlugin]}
              data={dataToRender}
              options={options}
            />
            <div id={legendContainerId} className="w-100 p-3" />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(DoughnutChart);
