import React from 'react';
import DoughnutChart from './DoughnutChart';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { htmlLegendPlugin } from './components/LegendPlugin';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChartContainer = ({
  data,
  colors,
  legendContainerId,
  ...restProps
}) => {
  const { i18n } = useTranslation();

  const dataToRender = {
    labels: data.items.map((ele) => `${ele.title} (${ele.numbers})`),
    datasets: [
      {
        label: data.title,
        data: data.items.map((ele) => ele.numbers),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    radius: '75%',
    cutout: '68%',
    plugins: {
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: legendContainerId,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label;
          },
        },
        rtl: i18n.language === 'ar',
      },
      legend: {
        display: false,
        position: 'bottom',
      },
    },
  };

  return (
    <DoughnutChart
      data={data}
      htmlLegendPlugin={htmlLegendPlugin}
      dataToRender={dataToRender}
      options={options}
      legendContainerId={legendContainerId}
      title={data.title}
      {...restProps}
    />
  );
};

export default React.memo(DoughnutChartContainer);
