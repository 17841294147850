import React from 'react';
import styles from './PagesHeader.styles.scss';
import { Breadcrumbs } from '../../Breadcrumbs';
import cn from 'classnames';

const PagesHeader = ({
  title,
  routes,
  withBreadcrumb,
  titleClassName,
  isTaxReturn,
}) => {
  return (
    <div className={styles.header}>
      {withBreadcrumb && (
        <Breadcrumbs isTaxReturn={isTaxReturn} routeNames={routes} />
      )}
      <p className={cn(styles.title, 'mx-2', titleClassName)}>{title}</p>
    </div>
  );
};

export default React.memo(PagesHeader);
