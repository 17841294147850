import axios from 'axios';
import i18n from './i18n';
import * as constants from '../constants/common';

const servicesInstance = axios.create({});
const smeInstance = axios.create({});
const certificationInstance = axios.create({});
const printInstance = axios.create({});
const taxReturnServiceInstance = axios.create({});

export const initiateServiceInstance = ({
  API_URL,
  ONBOARDING_GRAVITEE_KEY,
}) => {
  servicesInstance.defaults.baseURL = API_URL;
  servicesInstance.defaults.headers.common['X-Gravitee-Api-Key'] =
    ONBOARDING_GRAVITEE_KEY;
};

export const initiateSmeInstance = ({ SME_URL, SME_GRAVITEE_KEY }) => {
  smeInstance.defaults.baseURL = SME_URL;
  smeInstance.defaults.headers.common['X-Gravitee-Api-Key'] = SME_GRAVITEE_KEY;
};

export const initiateCertificationInstance = ({
  API_URL,
  CERTIFICATION_GRAVITEE_KEY,
}) => {
  certificationInstance.defaults.baseURL = API_URL;
  certificationInstance.defaults.headers.common['X-Gravitee-Api-Key'] =
    CERTIFICATION_GRAVITEE_KEY;
};

export const initiatePrintInstance = ({ PRINT_URL }) => {
  printInstance.defaults.baseURL = PRINT_URL;
  printInstance.defaults.responseType = 'arraybuffer';
};

export const initiateTaxReturnInstance = ({ TAX_RETURN_URL }) => {
  taxReturnServiceInstance.defaults.baseURL = TAX_RETURN_URL;
};

const _interceptResponseSuccess = (response) => {
  return response;
};

const _interceptResponseError = (error) => {
  if (!error.response) {
    return Promise.reject({ message: 'Internal Server Error' });
  }

  return Promise.reject(error.response.data);
};

const _interceptRequest = (request) => {
  const token = sessionStorage.getItem(constants.TOKEN_KEY);
  const language = i18n.language || 'ar';

  request.headers.common['Accept-Language'] = language;

  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  }

  return request;
};

servicesInstance.interceptors.request.use(_interceptRequest);
servicesInstance.interceptors.response.use(
  _interceptResponseSuccess,
  _interceptResponseError,
);

smeInstance.interceptors.request.use(_interceptRequest);
smeInstance.interceptors.response.use(
  _interceptResponseSuccess,
  _interceptResponseError,
);

certificationInstance.interceptors.request.use(_interceptRequest);
certificationInstance.interceptors.response.use(
  _interceptResponseSuccess,
  _interceptResponseError,
);

printInstance.interceptors.request.use(_interceptRequest);
printInstance.interceptors.response.use(
  _interceptResponseSuccess,
  _interceptResponseError,
);

taxReturnServiceInstance.interceptors.request.use(_interceptRequest);
taxReturnServiceInstance.interceptors.response.use(
  _interceptResponseSuccess,
  _interceptResponseError,
);

const instances = {
  servicesInstance,
  smeInstance,
  certificationInstance,
  printInstance,
  taxReturnServiceInstance,
};

export default instances;
