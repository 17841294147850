import React, { useState, useEffect } from 'react';
import PurchasesRegister from './PurchasesRegister';
import * as api from '../../../../services/apis';
import { useCustomContext } from '../../../../context/customContext';

const PurchasesRegisterContainer = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useCustomContext();

  const getRegisters = ({
    taxNumber,
    dateFrom = '',
    dateTo = '',
    invoiceType = '',
    searchQuery = '',
  }) => {
    setLoading(true);
    api
      .getPurchasesRegister({
        taxNumber,
        dateFrom,
        dateTo,
        invoiceType,
        searchQuery,
      })
      .then((resp) => {
        setInvoices(resp.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log({ e });
        setInvoices([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      getRegisters({ taxNumber: user.tax_number });
    }
  }, [user]);

  return (
    <PurchasesRegister
      invoices={invoices}
      getRegisters={getRegisters}
      loading={loading}
    />
  );
};

export default React.memo(PurchasesRegisterContainer);
