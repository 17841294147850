import React from 'react';
import Filters from './Filters';
import { initialValues } from './Filters.formConfig';
import { filterData } from './Filters.helpers';

const FiltersContainer = ({ originalSolutions, setRenderSolutions }) => {
  const handleSubmitFilters = (data) => {
    const filteredData = filterData(data, originalSolutions);
    setRenderSolutions(filteredData);
  };

  const handleReset = (resetForm) => {
    resetForm({ values: initialValues });
    setRenderSolutions(originalSolutions);
  };

  return (
    <Filters
      onResetFormClick={handleReset}
      onSubmitFilters={handleSubmitFilters}
    />
  );
};

export default React.memo(FiltersContainer);
