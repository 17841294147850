import React from 'react';
import styles from './ManageExistingSolutions.styles.scss';
import { MainLayout } from '../../components/MainLayout';
import { PagesHeader } from '../../components/shared/PagesHeader';
import { useTranslation } from 'react-i18next';
import { ROUTES } from './ManageExistingSolutions.config';
import SolutionsTable from './components/SolutionsTable/SolutionsTable';
import { Filters } from './components/Filters';

const ManageExistingSolutions = ({
  solutions,
  loading,
  setRenderSolutions,
  originalSolutions,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout eInvoicing={true} bannerClassName={styles.banner}>
      <PagesHeader
        withBreadcrumb={true}
        title={t('manage_existing_solution')}
        routes={ROUTES}
        titleClassName={styles.title}
      />
      <div className={styles.inner}>
        <Filters
          originalSolutions={originalSolutions}
          setRenderSolutions={setRenderSolutions}
        />
        <SolutionsTable solutions={solutions} loading={loading} />
      </div>
    </MainLayout>
  );
};

export default React.memo(ManageExistingSolutions);
