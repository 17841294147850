import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../../../../../helpers/showToast';

const FormErrorToast = () => {
  const { errors, submitCount } = useFormikContext();

  const { t } = useTranslation();

  useEffect(() => {
    if (submitCount > 0 && Object.keys(errors).length > 0) {
      showToast({
        data: {
          variant: 'error',
          text: t('create_invoice_validation.fill_all_fields'),
        },
      });
    }
  }, [submitCount]);

  return null;
};

export default React.memo(FormErrorToast);
