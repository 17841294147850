import React from 'react';
import cn from 'classnames';
import { getIcons } from './Icon.helpers';

import styles from './Icon.styles.scss';

const Icon = ({ className, name, hidden, ...restProps }) => {
  const icons = getIcons();

  const IconComponent = icons[name];

  if (!IconComponent || (hidden && hidden())) {
    return null;
  }

  return (
    <IconComponent
      {...restProps}
      className={cn(styles.icon, className, name === 'info' ? styles.info : '')}
    />
  );
};

export default React.memo(Icon);
