import * as Yup from 'yup';

import { regexValidation } from '../../../../../constants/regexValidation';

import { SELECT_AN_OPTION_LABEL } from '../../../../../constants/common';

export const FIELDS_MAX_LENGTH = {
  name: 127,
  address: 127,
  VAT: 15,
  buildingNumber: 4,
  postalCode: 5,
  idNumber: 20,
  purchaseOrderNumber: 127,
  contractNumber: 127,
  description: 127,
  paymentTerms: 127,
  bankName: 127,
  accountNumber: 127,
  specialTaxTreatment: 127,
  notes: 500,
};

export const discountMaxValidation = ({
  t,
  unitPrice,
  quantity,
  discountAmountSchema,
}) => {
  if (!unitPrice || !quantity) {
    return discountAmountSchema;
  }

  return discountAmountSchema.max(
    +unitPrice * +quantity,
    t('create_invoice_validation.discount_amount'),
  );
};
export const discountPercentageMaxValidation = ({
  t,
  unitPrice,
  quantity,
  discountPercentageSchema,
}) => {
  if (!unitPrice || !quantity) {
    return discountPercentageSchema;
  }

  return discountPercentageSchema.max(
    100,
    t('create_invoice_validation.discount_percentage'),
  );
};

export const invoiceTypeValidationSchema = ({ t, type }) => {
  const defaultShape = {
    invoiceTypeDescription: Yup.string().required(
      t('create_invoice_validation.required'),
    ),
    invoiceTypeTransaction: Yup.string().required(
      t('create_invoice_validation.required'),
    ),
  };

  if (type === 'creditDebit') {
    return Yup.object().shape({
      ...defaultShape,
      noteType: Yup.string().required(t('create_invoice_validation.required')),
    });
  } else {
    return Yup.object().shape({
      ...defaultShape,
    });
  }
};

export const invoiceInformationValidationSchema = ({ t }) => {
  return Yup.object().shape({
    supplyStartDate: Yup.string().required(
      t('create_invoice_validation.required'),
    ),
    supplyEndDate: Yup.string().required(
      t('create_invoice_validation.required'),
    ),
  });
};

export const sellerIdentificationValidationSchema = ({ t }) => {
  return Yup.object().shape({
    vatNumber: Yup.string().required(t('create_invoice_validation.required')),
    // .matches(regexValidation.vatNumber, 'VAT Number Should be 15 digits'),
    sellerEnglishName: Yup.string()
      .required(t('create_invoice_validation.required'))
      .trim(),
    additionalSellerIdType: Yup.string(),
    additionalSellerId: Yup.string()
      .trim()
      .when(
        ['additionalSellerIdType'],
        (additionalSellerIdType, additionalSellerIdSchema) => {
          if (
            additionalSellerIdType &&
            additionalSellerIdType !== SELECT_AN_OPTION_LABEL
          ) {
            return additionalSellerIdSchema.required(
              t('create_invoice_validation.required'),
            );
          }
          return additionalSellerIdSchema;
        },
      ),
  });
};

export const sellerAddressValidationSchema = ({ t }) => {
  return Yup.object().shape({
    sellerStreetEn: Yup.string()
      .required(t('create_invoice_validation.required'))
      .trim(),
    additionalSellerStreetEn: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
    sellerBuildingNumber: Yup.number()
      .typeError(t('create_invoice_validation.building_number'))
      // .max(4)
      .required(t('create_invoice_validation.required')),
    additionalSellerBuildingNumber: Yup.number().typeError(
      t('create_invoice_validation.additional_number'),
    ), //.max(4),
    sellerCity: Yup.string().required(t('create_invoice_validation.required')),
    sellerPostalCode: Yup.number()
      .typeError(t('create_invoice_validation.postal_code'))
      .required(t('create_invoice_validation.required')),
    // .max(5)
    // .min(5),
    sellerStateEn: Yup.string()
      .required(t('create_invoice_validation.required'))
      .trim(),
    sellerNeighborhoodEn: Yup.string()
      .required(t('create_invoice_validation.required'))
      .trim(),
  });
};

export const buyerIdentificationValidationSchema = ({ t }) => {
  return Yup.object().shape({
    buyerEnglishName: Yup.string()
      .required(t('create_invoice_validation.required'))
      .trim(),
    additionalBuyerIdType: Yup.string(),
    additionalBuyerId: Yup.string().when(
      ['additionalBuyerIdType'],
      (additionalBuyerIdType, additionalBuyerIdSchema) => {
        if (additionalBuyerIdType) {
          return additionalBuyerIdSchema
            .required(t('create_invoice_validation.required'))
            .trim();
        } else {
          return additionalBuyerIdSchema.matches(
            regexValidation.trim,
            t('create_invoice_validation.no_white_space'),
          );
        }
      },
    ),
    buyerVatNumber: Yup.string().matches(
      regexValidation.vatNumber,
      'VAT Number Should be 15 digits',
    ),
  });
};

export const buyerAddressValidationSchema = ({ t }) => {
  return Yup.object().shape({
    buyerStreetEn: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
    // .required(t('create_invoice_validation.required'))
    // .trim(),

    additionalBuyerStreetEn: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
    buyerBuildingNumber: Yup.number().typeError(
      t('create_invoice_validation.building_number'),
    ),
    // .required(t('create_invoice_validation.required'))
    additionalBuyerBuildingNumber: Yup.number().typeError(
      t('create_invoice_validation.additional_number'),
    ),
    buyerCity: Yup.string(),
    buyerPostalCode: Yup.number().typeError(
      t('create_invoice_validation.postal_code'),
    ),
    // .required(t('create_invoice_validation.required')),
    buyerStateEn: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
    // .required(t('create_invoice_validation.required'))
    // .trim(),
    buyerNeighborhoodEn: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
    // .required(t('create_invoice_validation.required'))
    // .trim(),
  });
};

export const orderReferenceValidationSchema = ({ t }) => {
  return Yup.object().shape({
    purchaseOrderNumber: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
    contactNumber: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
  });
};

export const lineItemsValidationSchema = ({ t }) => {
  const tempItemShape = () => ({
    descriptionEn: Yup.string()
      // .required(t('create_invoice_validation.required'))
      .matches(
        regexValidation.trim,
        t('create_invoice_validation.no_white_space'),
      ),
    unitPrice: Yup.string()
      // .required(t('create_invoice_validation.required'))
      .matches(
        regexValidation.numberWithMaxSixDecimals,
        t('create_invoice_validation.unitPrice'),
      )
      .typeError(t('create_invoice_validation.unitPrice'))
      .test(
        'unitPrice',
        t('create_invoice_validation.more_than_zero'),
        (value) => +value > 0 || !value,
      ),
    quantity: Yup.number()
      // .required(t('create_invoice_validation.required'))
      .typeError(t('create_invoice_validation.quantity'))
      .positive(t('create_invoice_validation.quantity')),
    discountAmount: Yup.number()
      .typeError(t('create_invoice_validation.discount_amount'))
      .moreThan(-1, t('create_invoice_validation.discount_amount'))
      .when(
        ['unitPrice', 'quantity'],
        (unitPrice, quantity, discountAmountSchema) =>
          discountMaxValidation({
            t,
            unitPrice,
            quantity,
            discountAmountSchema,
          }),
      ),
    discountPercentage: Yup.number()
      .typeError(t('create_invoice_validation.discount_percentage'))
      .moreThan(-1, t('create_invoice_validation.discount_percentage'))
      .lessThan(100.000001, t('create_invoice_validation.discount_percentage'))
      .when(
        ['unitPrice', 'quantity'],
        (unitPrice, quantity, discountPercentageSchema) =>
          discountPercentageMaxValidation({
            t,
            unitPrice,
            quantity,
            discountPercentageSchema,
          }),
      ),
    vatRate: Yup.string().required(t('create_invoice_validation.required')),
  });

  return Yup.object().shape({
    tempItem: Yup.object().shape({ ...tempItemShape() }),
  });
};

export const paymentTermsValidationSchema = ({ t }) => {
  return Yup.object().shape({
    paymentMethod: Yup.string().required(
      t('create_invoice_validation.required'),
    ),
    bankNameEn: Yup.string()
      .when(['paymentMethod'], (paymentMethod, bankNameSchema) => {
        return paymentMethod === 'BANK_TRANSFER'
          ? bankNameSchema.required(t('create_invoice_validation.required'))
          : bankNameSchema;
      })
      .matches(
        regexValidation.trim,
        t('create_invoice_validation.no_white_space'),
      ),
    accountNumber: Yup.string()
      .when(['paymentMethod'], (paymentMethod, accountNumberSchema) => {
        return paymentMethod === 'BANK_TRANSFER'
          ? accountNumberSchema.required(
              t('create_invoice_validation.required'),
            )
          : accountNumberSchema;
      })
      .matches(
        regexValidation.trim,
        t('create_invoice_validation.no_white_space'),
      ),
    paymentTermsEn: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
  });
};

export const referenceValidationsSchema = ({ t }) => {
  return Yup.object().shape({
    originalInvoiceReference: Yup.string().required(
      t('create_invoice_validation.required'),
    ),
    originalGrossAmount: Yup.string().required(
      t('create_invoice_validation.required'),
    ),
    noteReasonOfIssuance: Yup.string().required(
      t('create_invoice_validation.required'),
    ),
  });
};

export const notesValidationSchema = ({ t }) => {
  return Yup.object().shape({
    specialTaxTreatmentEn: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
    notesEn: Yup.string().matches(
      regexValidation.trim,
      t('create_invoice_validation.no_white_space'),
    ),
  });
};
