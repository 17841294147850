import React, { useState, useEffect } from 'react';
import ViewInvoice from './ViewInvoice';
import { useParams } from 'react-router-dom';
import { useCustomContext } from '../../../../context/customContext';
import { useKeycloak } from '@react-keycloak/web';
import {
  downloadXml,
  downloadPDFInvoice,
} from '../../../../helpers/invoiceHelpers';
import * as api from '../../../../services/apis';

const ViewInvoiceContainer = () => {
  const { type, id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const { user } = useCustomContext();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (user) {
      api.getInvoiceById(id, user.tax_number).then((resp) => {
        setInvoice(resp.data);
        setLoading(false);
      });
    }
  }, [user]);

  const handleDownloadXmlClick = () => {
    downloadXml(invoice.xml, invoice.invoiceNumber);
  };

  const handleDownloadPDFClick = () => {
    if (keycloak) {
      setDownloading(true);
      const currency = keycloak.realm === 'eiv' ? 'AED' : '';
      downloadPDFInvoice({
        data: invoice,
        currency,
        uuid: id,
        setLoading: setDownloading,
      });
    }
  };

  return (
    <ViewInvoice
      type={type}
      loading={loading}
      onDownloadXmlClick={handleDownloadXmlClick}
      onDownloadPdfClick={handleDownloadPDFClick}
      invoice={invoice}
      downloading={downloading}
    />
  );
};

export default React.memo(ViewInvoiceContainer);
