import React from 'react';
import { Icon } from '../../../Icon';
import { Line } from '../Line';
import styles from './Step.styles.scss';
import cn from 'classnames';

const Step = ({ index, length, activeIndex, stepClassName }) => {
  if (index === length - 1) {
    return index === activeIndex ? (
      <div id={index} className={cn(styles.lastStep, stepClassName || 'col-1')}>
        <Icon width="20px" name="greenBorderCircle" />
        {/* <Line /> */}
      </div>
    ) : (
      <div className={cn(styles.lastStep, 'col-1')}>
        <Icon width="20px" name="grayCircle" />
        {/* <Line /> */}
      </div>
    );
  } else {
    return index === activeIndex ? (
      <div id={index} className={cn(stepClassName || 'col-1', styles.inner)}>
        <Icon width="20px" name="greenBorderCircle" />
        <Line name="grayLine" />
      </div>
    ) : index < activeIndex ? (
      <div id={index} className={cn(stepClassName || 'col-1', styles.inner)}>
        <Icon width="20px" name="done" />
        <Line name="greenLine" />
      </div>
    ) : (
      <div id={index} className={cn(stepClassName || 'col-1', styles.inner)}>
        <Icon width="20px" name="grayCircle" />
        <Line name="grayLine" />
      </div>
    );
  }
};

export default React.memo(Step);
