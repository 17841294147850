export const handleAmountBlur = async ({ e, setFieldValue, values }) => {
  const standardRatedSales = values?.sales?.standardRatedSales || 0.0;
  const standardTaxAmount = values?.sales?.standardRateTaxAmount || 0.0;
  const reducedRate = values?.sales?.reducedRate || 0.0;
  const reducedTaxAmount = values?.sales?.reducedRateTaxAmount || 0.0;
  const governmentSales = values?.sales?.governmentSales || 0.0;
  const zeroRatedSales = values?.sales?.zeroRatedSales || 0.0;
  const exports = values?.sales?.exports || 0.0;
  const exempt = values?.sales?.exempt || 0.0;

  const standardRatedPurchase = values?.purchases?.standardRatedPurchase || 0.0;
  const standardRateTaxAmount = values?.purchases?.standardRateTaxAmount || 0.0;
  const reducedRatePurchase = values?.purchases?.reducedRate || 0.0;
  const reducedRateTaxAmount = values?.purchases?.reducedRateTaxAmount || 0.0;
  const importsSubjectToVat = values?.purchases?.importsSubjectToVat || 0.0;
  const importsSubjectToVatTaxAmount =
    values?.purchases?.importsSubjectToVatTaxAmount || 0.0;
  const reducedImportsSubjectToVat =
    values?.purchases?.reducedImportsSubjectToVat || 0.0;
  const reducedImportsSubjectToVatTaxAmount =
    values?.purchases?.reducedImportsSubjectToVatTaxAmount || 0.0;
  const importsReverseCharge = values?.purchases?.importsReverseCharge || 0.0;
  const importsReverseChargeTaxAmount =
    values?.purchases?.importsReverseChargeTaxAmount || 0.0;
  const reducedImportsReverseCharge =
    values?.purchases?.reducedImportsReverseCharge || 0.0;
  const reducedImportsReverseChargeTaxAmount =
    values?.purchases?.reducedImportsReverseChargeTaxAmount || 0.0;
  const zeroRatedPurchases = values?.purchases?.zeroRatedSales || 0.0;
  const exemptPurchases = values?.purchases?.exempt || 0.0;

  switch (e?.target?.name) {
    case 'sales.standardRatedSales': {
      const adjustmentValue = +values?.newSales?.standardAdj || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((value - adjustmentValue) * 0.15).toFixed(2);
      const totalSales = (
        +value +
        +reducedRate +
        +governmentSales +
        +zeroRatedSales +
        +exports +
        +exempt
      ).toFixed(2);
      const totalSalesTaxAmount = (+taxAmount + +reducedTaxAmount).toFixed(2);
      setFieldValue('sales.standardRateTaxAmount', taxAmount);
      setFieldValue('newSales.standardAmount', value);
      setFieldValue('newSales.standardVat', taxAmount);
      setFieldValue('sales.totalSales', totalSales);
      setFieldValue('newSales.totalSales', totalSales);
      setFieldValue('sales.totalSalesTaxAmount', totalSalesTaxAmount);
      setFieldValue('newSales.totalSalesVat', totalSalesTaxAmount);

      break;
    }
    case 'sales.reducedRate': {
      const adjustmentValue = +values.newSales.reducedAdj || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((value - adjustmentValue) * 0.05).toFixed(2);
      const totalSales = (
        +value +
        +standardRatedSales +
        +governmentSales +
        +zeroRatedSales +
        +exports +
        +exempt
      ).toFixed(2);
      const totalSalesTaxAmount = (+taxAmount + +standardTaxAmount).toFixed(2);
      setFieldValue('sales.reducedRateTaxAmount', taxAmount);
      setFieldValue('newSales.reducedAmount', value);
      setFieldValue('newSales.reducedVat', taxAmount);
      setFieldValue('sales.totalSales', totalSales);
      setFieldValue('newSales.totalSales', totalSales);
      setFieldValue('sales.totalSalesTaxAmount', totalSalesTaxAmount);
      setFieldValue('newSales.totalSalesVat', totalSalesTaxAmount);
      break;
    }
    case 'sales.governmentSales': {
      const value = +e?.target?.value || 0;
      const totalSales = (
        +value +
        +standardRatedSales +
        +reducedRate +
        +zeroRatedSales +
        +exports +
        +exempt
      ).toFixed(2);
      setFieldValue('newSales.reducedAmount', value);
      setFieldValue('sales.totalSales', totalSales);
      setFieldValue('newSales.totalSales', totalSales);
      break;
    }
    case 'sales.zeroRatedSales': {
      const value = +e?.target?.value || 0;
      const totalSales = (
        +value +
        +standardRatedSales +
        +reducedRate +
        +governmentSales +
        +exports +
        +exempt
      ).toFixed(2);
      setFieldValue('newSales.zeroRated', value);
      setFieldValue('sales.totalSales', totalSales);
      setFieldValue('newSales.totalSales', totalSales);
      break;
    }
    case 'sales.exports': {
      const value = +e?.target?.value || 0;
      const totalSales = (
        +value +
        +standardRatedSales +
        +reducedRate +
        +governmentSales +
        +zeroRatedSales +
        +exempt
      ).toFixed(2);
      setFieldValue('newSales.exports', value);
      setFieldValue('sales.totalSales', totalSales);
      setFieldValue('newSales.totalSales', totalSales);
      break;
    }
    case 'sales.exempt': {
      const value = +e?.target?.value || 0;
      const totalSales = (
        +value +
        +standardRatedSales +
        +reducedRate +
        +governmentSales +
        +zeroRatedSales +
        +exports
      ).toFixed(2);
      setFieldValue('newSales.exempt', value);
      setFieldValue('sales.totalSales', totalSales);
      setFieldValue('newSales.totalSales', totalSales);
      break;
    }
    case 'purchases.standardRatedPurchase': {
      const adjustmentValue = +values?.newPurchases?.standardRatedAdj || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((value - adjustmentValue) * 0.15).toFixed(2);
      const totalPurchases = (
        +value +
        +reducedRatePurchase +
        +importsSubjectToVat +
        +reducedImportsSubjectToVat +
        +importsReverseCharge +
        +reducedImportsReverseCharge +
        +zeroRatedPurchases +
        +exemptPurchases
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +reducedRateTaxAmount +
        +importsSubjectToVatTaxAmount +
        +reducedImportsSubjectToVatTaxAmount +
        +importsReverseChargeTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);

      setFieldValue('purchases.standardRateTaxAmount', taxAmount);
      setFieldValue('newPurchases.standardRatedPurchase', value);
      setFieldValue('newPurchases.standardRateTaxAmount', taxAmount);
      setFieldValue('purchases.totalPurchases', totalPurchases);
      setFieldValue('newPurchases.totalPurchases', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'purchases.reducedRate': {
      const adjustmentValue = +values?.newPurchases?.reducedRateAdj || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((value - adjustmentValue) * 0.05).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedPurchase +
        +importsSubjectToVat +
        +reducedImportsSubjectToVat +
        +importsReverseCharge +
        +reducedImportsReverseCharge +
        +zeroRatedPurchases +
        +exemptPurchases
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmount +
        +importsSubjectToVatTaxAmount +
        +reducedImportsSubjectToVatTaxAmount +
        +importsReverseChargeTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);

      setFieldValue('purchases.reducedRateTaxAmount', taxAmount);
      setFieldValue('newPurchases.reducedRate', value);
      setFieldValue('newPurchases.reducedRateTaxAmount', taxAmount);
      setFieldValue('purchases.totalPurchases', totalPurchases);
      setFieldValue('newPurchases.totalPurchases', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'purchases.importsSubjectToVat': {
      const adjustmentValue =
        +values?.newPurchases?.importsSubjectToVatAdj || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((value - adjustmentValue) * 0.15).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedPurchase +
        +reducedRatePurchase +
        +reducedImportsSubjectToVat +
        +importsReverseCharge +
        +reducedImportsReverseCharge +
        +zeroRatedPurchases +
        +exemptPurchases
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmount +
        +reducedRatePurchase +
        +reducedImportsSubjectToVatTaxAmount +
        +importsReverseChargeTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);

      setFieldValue('purchases.importsSubjectToVatTaxAmount', taxAmount);
      setFieldValue('newPurchases.importsSubjectToVat', value);
      setFieldValue('newPurchases.reducedRateTaxAmount', taxAmount);
      setFieldValue('purchases.totalPurchases', totalPurchases);
      setFieldValue('newPurchases.totalPurchases', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'purchases.reducedImportsSubjectToVat': {
      const adjustmentValue =
        +values?.newPurchases?.reducedImportsSubjectToVatAdj || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((value - adjustmentValue) * 0.05).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedPurchase +
        +importsSubjectToVat +
        +reducedRatePurchase +
        +importsReverseCharge +
        +reducedImportsReverseCharge +
        +zeroRatedPurchases +
        +exemptPurchases
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmount +
        +importsSubjectToVatTaxAmount +
        +reducedRatePurchase +
        +importsReverseChargeTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);

      setFieldValue('purchases.reducedImportsSubjectToVatTaxAmount', taxAmount);
      setFieldValue('newPurchases.reducedImportsSubjectToVat', value);
      setFieldValue(
        'newPurchases.reducedImportsSubjectToVatTaxAmount',
        taxAmount,
      );
      setFieldValue('purchases.totalPurchases', totalPurchases);
      setFieldValue('newPurchases.totalPurchases', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'purchases.importsReverseCharge': {
      const adjustmentValue = +values?.newPurchases?.reverseImportsAdj || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((value - adjustmentValue) * 0.15).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedPurchase +
        +reducedRatePurchase +
        +reducedImportsSubjectToVat +
        +importsSubjectToVat +
        +reducedImportsReverseCharge +
        +zeroRatedPurchases +
        +exemptPurchases
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmount +
        +reducedRatePurchase +
        +reducedImportsSubjectToVatTaxAmount +
        +importsSubjectToVatTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);

      setFieldValue('purchases.importsReverseChargeTaxAmount', taxAmount);
      setFieldValue('newPurchases.reverseImports', value);
      setFieldValue('newPurchases.reverseImportsTaxAmount', taxAmount);
      setFieldValue('purchases.totalPurchases', totalPurchases);
      setFieldValue('newPurchases.totalPurchases', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'purchases.reducedImportsReverseCharge': {
      const adjustmentValue =
        +values?.newPurchases?.reducedReverseImportsAdj || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((value - adjustmentValue) * 0.05).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedPurchase +
        +importsSubjectToVat +
        +reducedRatePurchase +
        +importsReverseCharge +
        +reducedImportsSubjectToVat +
        +zeroRatedPurchases +
        +exemptPurchases
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmount +
        +importsSubjectToVatTaxAmount +
        +reducedRatePurchase +
        +importsReverseChargeTaxAmount +
        +reducedImportsSubjectToVatTaxAmount
      ).toFixed(2);

      setFieldValue(
        'purchases.reducedImportsReverseChargeTaxAmount',
        taxAmount,
      );
      setFieldValue('newPurchases.reducedReverseImports', value);
      setFieldValue('newPurchases.reducedReverseImportsTaxAmount', taxAmount);
      setFieldValue('purchases.totalPurchases', totalPurchases);
      setFieldValue('newPurchases.totalPurchases', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'purchases.zeroRatedSales': {
      const value = +e?.target?.value || 0;
      const totalPurchases = (
        +value +
        +standardRatedPurchase +
        +importsSubjectToVat +
        +reducedRatePurchase +
        +importsReverseCharge +
        +reducedImportsSubjectToVat +
        +reducedImportsReverseCharge +
        +exemptPurchases
      ).toFixed(2);

      setFieldValue('newPurchases.zeroRated', value);
      setFieldValue('purchases.totalPurchases', totalPurchases);
      setFieldValue('newPurchases.totalPurchases', totalPurchases);
      break;
    }
    case 'purchases.exempt': {
      const value = +e?.target?.value || 0;
      const totalPurchases = (
        +value +
        +standardRatedPurchase +
        +importsSubjectToVat +
        +reducedRatePurchase +
        +importsReverseCharge +
        +reducedImportsSubjectToVat +
        +reducedImportsReverseCharge +
        +zeroRatedPurchases
      ).toFixed(2);

      setFieldValue('newPurchases.exempt', value);
      setFieldValue('purchases.totalPurchases', totalPurchases);
      setFieldValue('newPurchases.totalPurchases', totalPurchases);
      break;
    }
    case 'correctionFromPreviousValue': {
      const correctionFromPreviousAdj = +values?.correctionFromPreviousAdj || 0;
      const value = +e.target.value || 0;
      const correctionFromPrevious = (
        (value - correctionFromPreviousAdj) *
        0.15
      ).toFixed(2);
      setFieldValue('correctionFromPrevious', correctionFromPrevious);
      break;
    }
  }

  const correctionFromPrevious = values?.correctionFromPrevious || 0.0;
  const vatCreditCarriedForward = 0;
  const totalSales = +values?.sales?.totalSalesTaxAmount || 0;
  const totalPurchases = +values?.purchases?.totalPurchasesTaxAmount || 0;
  const currentTotalVatDue = (totalSales - totalPurchases).toFixed(2);

  const netVatDue = (
    +currentTotalVatDue +
    +correctionFromPrevious -
    +vatCreditCarriedForward
  ).toFixed(2);

  setFieldValue('currentTotalVatDue', currentTotalVatDue);
  setFieldValue('correctionFromPrevious', +correctionFromPrevious);
  setFieldValue('vatCreditCarriedForward', +vatCreditCarriedForward);
  setFieldValue('netVatDue', netVatDue);
};
