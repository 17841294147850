import React from 'react';
import styles from './ReturnVatForm.styles.scss';
import { Questions } from './components/Questions';
import { SalesForm } from './components/SalesForm';

const ReturnVatFrom = ({
  handleReducedRadio,
  handleGovernmentRadio,
  isReduced,
  sales,
  newSales,
  values,
  handleAmountBlur,
  handleAdjustmentBlur,
  handleSubmit,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <Questions
          handleReducedRadio={handleReducedRadio}
          handleGovernmentRadio={handleGovernmentRadio}
          styles={styles}
        />
        <SalesForm
          newSales={newSales}
          isReduced={isReduced}
          sales={sales}
          styles={styles}
          values={values}
          handleAmountBlur={handleAmountBlur}
          handleAdjustmentBlur={handleAdjustmentBlur}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default React.memo(ReturnVatFrom);
