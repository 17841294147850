import React from 'react';
import styles from './TaxpayerDetails.styles.scss';
import { Disclaimer } from '../../../../../components/shared/Disclaimer';
import { InputContainer } from './components/InputContainer';
import { Button } from '../../../../../components/shared/Button';
import cn from 'classnames';
import { randomKey } from '../../../../../helpers/common';

const TaxpayerDetails = ({
  values,
  isChecked,
  onContinue,
  setIsChecked,
  isApproved,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <p className={styles.title}>Tax Return Details</p>
        <div className={styles.form}>
          <div className="row">
            {values.taxReturnDetails.map((item) => (
              <InputContainer
                className="col-4 my-3"
                key={randomKey()}
                name={item.key}
                value={item.value}
                label={item.label}
                disabled={item.disabled}
                id={item.id}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.box}>
        <p className={styles.title}>Taxpayer Information</p>
        <div className={styles.form}>
          <div className="row">
            {values.taxpayerInformation.map((item) => (
              <InputContainer
                className="col-4 my-3"
                key={randomKey()}
                name={item.key}
                value={item.value}
                label={item.label}
                disabled={item.disabled}
                id={item.id}
              />
            ))}
          </div>
        </div>
        {!isApproved && (
          <>
            <Disclaimer
              disclaimerText={values.disclaimerText}
              inputClassName={styles.checkbox}
              setIsChecked={setIsChecked}
            />
            <p className={cn(styles.notes, 'mt-3')}>
              {values.note}
              <a href={values.link}>{values.linkText}</a>
            </p>
            {isChecked && (
              <div className={styles.actionsContainer}>
                <a className={('mx-3', styles.cancelLink)} href="/tax-return">
                  Cancel
                </a>
                <Button
                  className={cn('mx-5 col-2', styles.continueBtn)}
                  onClick={onContinue}
                >
                  Step 3
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(TaxpayerDetails);
