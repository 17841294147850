export const PAGES = (type) => [
  {
    title: 'e_invoice',
    iconName: 'invoice',
    buttonName: 'Generate',
    redirectUrl: 'invoice',
  },
  {
    title: 'credit_debit',
    iconName: 'creditDebit',
    buttonName: 'Generate',
    redirectUrl: 'note',
  },
];

export const ROUTES = (type) => [
  {
    label:
      type === 'standard'
        ? 'generate_standard_title'
        : 'generate_simplified_title',
    value: '',
  },
  {
    label: 'sme',
    link: 'sme',
  },
  {
    label: 'breadcrumb_e_invoicing',
    link: 'e-invoicing',
  },
];
