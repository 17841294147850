import { getTaxPeriod } from '../../../../../helpers/taxReturnHelpers';
import moment from 'moment';

export const DATA = (values) => ({
  taxReturnDetails: [
    {
      label: 'Return Type',
      value: values.returnType,
      id: 'returnType',
      disabled: true,
    },
    {
      label: 'VAT Return',
      value: values.approved ? 'Billed' : 'New',
      id: 'vatReturn',
      disabled: true,
    },
    {
      label: 'Return Reference No.',
      value:
        values.referenceNumber === 'null' ? '' : values.referenceNumber || '',
      id: 'referenceNumber',
      disabled: true,
    },
    {
      label: 'Tax Period',
      value: getTaxPeriod({
        periodType: values.returnType,
        count: values.reportCount,
        dateFrom: moment(values.dateFrom),
      }),
      id: 'taxPeriod',
      disabled: true,
    },
    {
      label: 'Period From',
      value: moment(values.dateFrom).format('DD/MM/YYYY'),
      id: 'dateFrom',
      disabled: true,
    },
    {
      label: 'Period To',
      value: moment(values.dateTo).format('DD/MM/YYYY'),
      id: 'dateTo',
      disabled: true,
    },
  ],
  taxpayerInformation: [
    {
      label: 'TIN',
      value: values?.taxnumber?.substr(0, 10),
      id: 'tin',
      disabled: true,
    },
    {
      label: 'VAT Account Number',
      value: values.taxnumber,
      id: 'vat',
      disabled: true,
    },
    {
      label: 'Identification Number',
      value: '7007625615',
      id: 'idNumber',
      disabled: true,
    },
    {
      label: "Taxpayer's Name",
      value: values.taxpayerName,
      id: 'taxpayerName',
      disabled: true,
    },
    {
      label: 'Address',
      value: 'Olaya, Riyadh, Saudi Arabia',
      id: 'address',
      disabled: true,
    },
  ],
  disclaimerText:
    'Kindly confirm that the above information is correct before proceeding',
  note: "Note: In case you don't agree with above information, click on this link and confirm: ",
  link: '#',
  linkText: 'Change of Registration Form',
});
