import React from 'react';

const SectionHeader = ({ title, styles }) => {
  return (
    <div className={styles.sectionHeader}>
      <p className={styles.title}>{title}</p>
    </div>
  );
};

export default React.memo(SectionHeader);
