import React from 'react';
import ReactDOM from 'react-dom/client';

import { initTranslations } from './config/i18n';

import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import './assets/styles/style.scss';
import './App.css';

import App from './App.container';

initTranslations();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
