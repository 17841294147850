import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from './Breadcrumbs';

const BreadcrumbsContainer = (props) => {
  const history = useNavigate();
  const { i18n } = useTranslation();

  const handleHomeClick = () => {
    history(`/${i18n.language}`);
  };
  const handleRouteClick = (route) => {
    if (route) {
      history(`/${i18n.language}/${route}`);
    }
  };

  return (
    <Breadcrumbs
      {...props}
      onHomeClick={handleHomeClick}
      onRouteClick={handleRouteClick}
    />
  );
};

export default React.memo(BreadcrumbsContainer);
