import React, { useState } from 'react';
import NumberOfOTPs from './NumberOfOTPs';
import { MAX_NUMBER_OF_OTPS } from './NumberOfOTPs.config';

const NumberOfOTPsContainer = ({ ...restProps }) => {
  const [noOfOTPs, setNoOfOtps] = useState('');

  const handleChnage = (e) => {
    const { value } = e.target;
    if (value <= MAX_NUMBER_OF_OTPS) {
      setNoOfOtps(value);
    } else {
      return;
    }
  };

  return (
    <NumberOfOTPs {...restProps} noOfOTPs={noOfOTPs} onChange={handleChnage} />
  );
};

export default React.memo(NumberOfOTPsContainer);
