import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Breadcrumbs.styles.scss';
import cn from 'classnames';

const Breadcrumbs = ({
  routeNames,
  onHomeClick,
  onRouteClick,
  isTaxReturn,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.route}>
        {routeNames?.map((route, i) => (
          <React.Fragment key={i}>
            <p
              className={cn(styles.routeText, route.link ? styles.pointer : '')}
              onClick={() => onRouteClick(route.link)}
            >
              {t(route.label)}
            </p>
            <p className={cn(styles.separatorIcon, 'mx-2')}>{'<'}</p>
          </React.Fragment>
        ))}
        <p onClick={onHomeClick} className={styles.homeRouteText}>
          {isTaxReturn ? 'Taxpayer Portal' : t('common.home')}
        </p>
      </div>
      {/* <div className={styles.homeRoute} onClick={onHomeClick}>
      </div> */}
    </div>
  );
};

export default React.memo(Breadcrumbs);
