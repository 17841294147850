import { SELECT_AN_OPTION_LABEL } from '../constants/common';

export const scrollToTop = () => {
  window.scroll(0, 0);
};
export const isTrue = (value) => {
  return typeof value === 'boolean' ? value === true : value === 'true';
};

export const setSelectOption = (option, val) =>
  option && option !== SELECT_AN_OPTION_LABEL ? option : val;

export const randomKey = () => (Math.random() + 1).toString(36).substring(7);

export const stringToBool = (str) => (str === 'true' ? true : false);
