import React from 'react';
import { useTranslation } from 'react-i18next';
import { PAGES, ROUTES } from './EInvoicingPortal.config';
import { Dashboard } from '../../components/Dashboard';

const EInvoicingPortal = ({ SHOW_SME }) => {
  const { t } = useTranslation();
  return (
    <Dashboard
      isEInvoicing={true}
      title={t('e_invoicing_portal_header')}
      routes={ROUTES}
      pages={PAGES(SHOW_SME)}
      tileId={'e_invoicing'}
      withBreadcrumb={false}
    />
  );
};

export default React.memo(EInvoicingPortal);
