import React from 'react';
import { Field, getIn } from 'formik';

import { FormControl } from '../shared/FormControl';

const FormikField = ({
  required,
  name,
  label,
  component: Component,
  componentProps,
  controlProps,
}) => (
  <Field name={name}>
    {({ field, form }) => {
      const error = getIn(form.errors, field.name);
      const touched = getIn(form.touched, field.name);

      const hasError = touched && !!error;

      return (
        <FormControl
          hasError={hasError}
          error={error}
          label={label}
          required={required}
          {...controlProps}
        >
          {Component?.type?.name !== 'SelectField' ? (
            <Component
              hasError={hasError}
              name={name}
              field={field}
              form={form}
              {...componentProps}
            />
          ) : (
            <Component
              name={name}
              field={field}
              form={form}
              {...componentProps}
            />
          )}
        </FormControl>
      );
    }}
  </Field>
);

export default React.memo(FormikField);
