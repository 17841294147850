const keycloak = ({ KEYCLOAK_CLIENT_ID, KEYCLOAK_URL, KEYCLOAK_REALM }) => {
  const kc = new window.Keycloak({
    realm: KEYCLOAK_REALM,
    url: KEYCLOAK_URL,
    clientId: KEYCLOAK_CLIENT_ID,
  });
  return kc;
};

export default keycloak;
