import React from 'react';
import { PAGES, ROUTES } from './POCHome.config';
import { Dashboard } from '../../components/Dashboard';

const POCHome = ({ SHOW_SME }) => {
  return (
    <Dashboard
      isEInvoicing={true}
      title={'Indirect Taxes - Value Added Tax'}
      routes={ROUTES}
      pages={PAGES(SHOW_SME)}
      tileId={'e_invoicing'}
      withBreadcrumb={true}
      isTaxReturn={true}
    />
  );
};

export default React.memo(POCHome);
