import React from 'react';
import { PAGES, ROUTES } from './SolutionRegistration.config';
import { useTranslation } from 'react-i18next';
import { Dashboard } from '../../components/Dashboard';

const SolutionRegistration = () => {
  const { t } = useTranslation();

  return (
    <Dashboard
      isEInvoicing={true}
      pages={PAGES}
      routes={ROUTES}
      tileId={'sol_reg'}
      withBreadcrumb={true}
      title={t('solution_registration_header')}
    />
  );
};

export default React.memo(SolutionRegistration);
