import React, { useState } from 'react';
import Instructions from './Instructions';

const InstructionsContainer = ({ onContinue, onCancel }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Instructions
      onCancel={onCancel}
      onContinue={onContinue}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
    />
  );
};

export default React.memo(InstructionsContainer);
