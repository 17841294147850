export const LINE_ITEMS_COLUMNS = [
  {
    key: 'descriptionEn',
    label: 'Product or Service Description',
    _props: { scope: 'col' },
  },
  {
    key: 'unitPrice',
    label: 'Unit Price',
    _props: { scope: 'col' },
  },
  {
    key: 'quantity',
    label: 'Quantity',
    _props: { scope: 'col' },
  },
  {
    key: 'discountPercentage',
    label: 'Discount or rebate percentage',
    _props: { scope: 'col' },
  },
  {
    key: 'discountAmount',
    label: 'Discount or rebate amount',
    _props: { scope: 'col' },
  },
  {
    key: 'vatRate',
    label: 'VAT rate',
    _props: { scope: 'col' },
  },
  {
    key: 'vatAmount',
    label: 'VAT amount',
    _props: { scope: 'col' },
  },
  {
    key: 'totalAmount',
    label: 'Total',
    _props: { scope: 'col' },
  },
];
