export const TOKEN_KEY = 'auth_token';
export const API_URL = 'https://ota.agiletz.com';
export const SME_URL = 'https://smebackend.agiletz.com';
export const KEYCLOAK_URL = 'https://eiv-keycloak.dtme.tech/auth';
export const TAX_RETURN_URL = 'http://localhost:8086';
export const KEYCLOAK_REALM = 'eiv';
export const KEYCLOAK_CLIENT_ID = 'Login';
export const ONBOARDING_GRAVITEE_KEY = '';
export const SME_GRAVITEE_KEY = '';
export const CERTIFICATION_GRAVITEE_KEY = '';
export const PRINT_URL = 'http://localhost:4000';
export const SHOW_SME = 'true';
export const TAX_COUNTRY = 'UAE';
export const IS_POC = 'true';
export const THEMES = {
  OTA: {
    header: '#2B3960',
    footer: '#2B3960',
    primary: '#2B3960',
    secondary: '#465374',
    gray: '#F0F0F0',
    background: '#FAF9F9',
  },
  eiv: {
    header: '#000',
    footer: '#000',
    primary: '#26890D',
    secondary: '#42982c',
    gray: '#F0F0F0',
    background: '#FAF9F9',
  },
};
export const SELECT_AN_OPTION_LABEL = 'Select an option';
