import React from 'react';
import { MainLayout } from '../../../../components/MainLayout';
import { PagesHeader } from '../../../../components/shared/PagesHeader';
import { ROUTES, STEPS } from './CreateInvoice.config';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../../../../components/shared/BackButton';
import styles from './CreateInvoice.styles.scss';
import cn from 'classnames';
import { Stepper } from '../../../../components/shared/Stepper';
import { Section } from './components/Section';
import { SectionHeader } from './components/SectionHeader';

const CreateInvoice = ({
  type,
  category,
  activeStep,
  invoiceCount,
  invoiceLimit,
  formObject,
  handleStepSubmit,
  previousInvoices,
  loading,
  resetInvoice,
  taxCountry,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout className={cn('py-3', styles.main)} loading={loading}>
      <PagesHeader withBreadcrumb={true} routes={ROUTES(type, t, category)} />
      <BackButton />
      <Stepper steps={STEPS({ type, formObject })} activeStep={activeStep} />
      <SectionHeader
        invoiceCount={invoiceCount}
        invoiceLimit={invoiceLimit}
        styles={styles}
        title={activeStep.label}
      />
      <Section
        taxCountry={taxCountry}
        inputs={activeStep.inputs}
        validationSchema={activeStep.validationSchema}
        initialValues={activeStep.initialValues}
        handleFormSubmit={handleStepSubmit}
        activeStep={activeStep}
        t={t}
        type={type}
        previousInvoices={previousInvoices}
        resetInvoice={resetInvoice}
      />
    </MainLayout>
  );
};

export default React.memo(CreateInvoice);
