import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageTile from './PageTile';

const PageTileContainer = ({
  ishovered,
  redirectUrl,
  tileid,
  disabled,
  ...restProps
}) => {
  const history = useNavigate();
  const handleClick = () => {
    history(`${redirectUrl}`, { replace: false, relative: true });
  };

  const hoverStyles = () => {
    const { hovered, elementId } = ishovered;
    return hovered && elementId === tileid;
  };

  return (
    <PageTile
      disabled={disabled}
      {...restProps}
      handleClick={handleClick}
      hoverStyles={hoverStyles}
    />
  );
};

export default React.memo(PageTileContainer);
