import {
  NOTE_TYPES,
  ADDITIONAL_SELLER_ID_TYPES,
  CITIES,
  ADDITIONAL_BUYER_ID_TYPES,
  PAYMENT_METHOD,
  NOTE_ISSUANCE_REASONS,
  PREVIOUS_INVOICES,
} from '../../../../../config/invoice/options';
import {
  onValuesBlur,
  onVatRateChange,
  setOriginalInvoiceGross,
  handleSupplyStartDateChange,
  getTaxTypes,
} from '../../../../../helpers/invoiceHelpers';
import moment from 'moment';
import { FIELDS_MAX_LENGTH } from './validations';

export const invoiceTypeInputs = ({ type }) => {
  return [
    {
      name: 'invoiceTypeDescription',
      type: 'text',
      label: 'Invoice Type Description',
      id: 'invoiceTypeDescription',
      disabled: true,
      hide: false,
      required: false,
    },
    {
      name: 'invoiceTypeTransaction',
      type: 'text',
      label: 'Invoice Type Transaction',
      id: 'invoiceTypeTransaction',
      disabled: true,
      hide: false,
      required: false,
    },
    {
      name: 'noteType',
      type: 'select',
      options: NOTE_TYPES('en'),
      label: 'Type Of Note',
      id: 'noteType',
      disabled: false,
      hide: type !== 'creditDebit',
      required: false,
    },
  ];
};

export const invoiceInformationInputs = ({
  values,
  setFieldValue,
  setFieldTouched,
}) => [
  {
    name: 'invoiceReferenceNumber',
    type: 'text',
    label: 'Invoice Reference Number',
    id: 'invoiceReferenceNumber',
    disabled: true,
    hide: false,
    required: false,
  },
  {
    name: 'invoiceIssueDate',
    type: 'text',
    label: 'Invoice Issue Date',
    id: 'invoiceIssueDate',
    disabled: true,
    hide: false,
    required: false,
  },
  {
    name: 'supplyStartDate',
    type: 'datepicker',
    label: 'Supply Start Date',
    id: 'supplyStartDate',
    className: 'col-6',
    disabled: false,
    hide: false,
    required: true,
    disabledDays: [{ after: new Date() }],
    onChange: (value) =>
      handleSupplyStartDateChange({ value, setFieldTouched, setFieldValue }),
  },
  {
    name: 'supplyEndDate',
    type: 'datepicker',
    label: 'Supply End Date',
    id: 'supplyEndDate',
    className: 'col-6',
    disabled: !values.supplyStartDate,
    hide: false,
    disabledDays: [
      { before: moment(values.supplyStartDate, 'DD/MM/YYYY').toDate() },
    ],
    required: true,
  },
];

export const sellerIdentificationInputs = ({ initialValues }) => [
  {
    name: 'sellerEnglishName',
    type: 'text',
    label: 'Seller Name',
    id: 'sellerEnglishName',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.name,
  },
  {
    name: 'vatNumber',
    type: 'text',
    label: 'VAT Registration Number',
    id: 'vatNumber',
    disabled: initialValues.vatNumber,
    hide: false,
    required: true,
  },
  {
    name: 'groupVatNumber',
    type: 'text',
    label: 'Group VAT Registration Number',
    id: 'groupVatNumber',
    disabled: true,
    hide: false,
    required: false,
  },
  {
    name: 'additionalSellerIdType',
    type: 'select',
    label: 'Additional Seller ID Type',
    id: 'additionalSellerIdType',
    disabled: false,
    options: ADDITIONAL_SELLER_ID_TYPES('en'),
    hide: false,
    required: false,
  },
  {
    name: 'additionalSellerId',
    type: 'text',
    label: 'Additional Seller ID Number',
    id: 'additionalSellerId',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.idNumber,
  },
];

export const sellerAddressInputs = () => [
  {
    name: 'sellerStreetEn',
    type: 'text',
    label: 'Street Name',
    id: 'sellerStreetEn',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'additionalSellerStreetEn',
    type: 'text',
    label: 'Additional Street',
    id: 'additionalSellerStreetEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'sellerBuildingNumber',
    type: 'text',
    label: 'Building Number',
    id: 'sellerBuildingNumber',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.buildingNumber,
  },
  {
    name: 'additionalSellerBuildingNumber',
    type: 'text',
    label: 'Additional Number',
    id: 'additionalSellerBuildingNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.buildingNumber,
  },
  {
    name: 'sellerCity',
    type: 'select',
    options: CITIES('en'),
    label: 'City',
    id: 'sellerCity',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
  },
  {
    name: 'sellerPostalCode',
    type: 'text',
    label: 'Postal Code',
    id: 'sellerPostalCode',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.postalCode,
  },
  {
    name: 'sellerStateEn',
    type: 'text',
    label: 'Province/State',
    id: 'sellerStateEn',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'sellerNeighborhoodEn',
    type: 'text',
    label: 'Neighborhood',
    id: 'sellerNeighborhoodEn',
    disabled: false,
    hide: false,
    required: true,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'sellerCountryCode',
    type: 'text',
    label: 'Country Code',
    id: 'sellerCountryCode',
    disabled: true,
    hide: false,
    required: false,
  },
];

export const buyerIdentificationInputs = () => [
  {
    name: 'buyerEnglishName',
    type: 'text',
    label: 'Buyer Name',
    id: 'buyerEnglishName',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.name,
  },
  {
    name: 'buyerVatNumber',
    type: 'text',
    label: 'VAT Registration Number',
    id: 'buyerVatNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.VAT,
  },
  {
    name: 'buyerGroupVatNumber',
    type: 'text',
    label: 'Group VAT Registration Number',
    id: 'buyerGroupVatNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.VAT,
  },
  {
    name: 'additionalBuyerIdType',
    type: 'select',
    label: 'Additional Buyer ID Type',
    id: 'additionalBuyerIdType',
    disabled: false,
    options: ADDITIONAL_BUYER_ID_TYPES('en'),
    hide: false,
    required: false,
  },
  {
    name: 'additionalBuyerId',
    type: 'text',
    label: 'Additional Buyer ID Number',
    id: 'additionalBuyerId',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.idNumber,
  },
];

export const buyerAddressInputs = () => [
  {
    name: 'buyerStreetEn',
    type: 'text',
    label: 'Street Name',
    id: 'buyerStreetEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'additionalBuyerStreetEn',
    type: 'text',
    label: 'Additional Street',
    id: 'additionalBuyerStreetEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'buyerBuildingNumber',
    type: 'text',
    label: 'Building Number',
    id: 'buyerBuildingNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.buildingNumber,
  },
  {
    name: 'additionalBuyerBuildingNumber',
    type: 'text',
    label: 'Additional Number',
    id: 'additionalBuyerBuildingNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.buildingNumber,
  },
  {
    name: 'buyerCity',
    type: 'select',
    options: CITIES('en'),
    label: 'City',
    id: 'buyerCity',
    disabled: false,
    hide: false,
    required: false,
    className: 'col-6',
  },
  {
    name: 'buyerPostalCode',
    type: 'text',
    label: 'Postal Code',
    id: 'buyerPostalCode',
    disabled: false,
    hide: false,
    required: false,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.postalCode,
  },
  {
    name: 'buyerStateEn',
    type: 'text',
    label: 'Province/State',
    id: 'buyerStateEn',
    disabled: false,
    hide: false,
    required: false,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'buyerNeighborhoodEn',
    type: 'text',
    label: 'Neighborhood',
    id: 'buyerNeighborhoodEn',
    disabled: false,
    hide: false,
    required: false,
    className: 'col-6',
    maxlength: FIELDS_MAX_LENGTH.address,
  },
  {
    name: 'buyerCountryCode',
    type: 'text',
    label: 'Country Code',
    id: 'buyerCountryCode',
    disabled: true,
    hide: false,
    required: false,
  },
];

export const orderReferenceInputs = () => [
  {
    name: 'purchaseOrderNumber',
    type: 'text',
    label: 'Purchase Order Number',
    id: 'purchaseOrderNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.orderReferenceInputs,
  },
  {
    name: 'contactNumber',
    type: 'text',
    label: 'Contract number',
    id: 'contactNumber',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.contractNumber,
  },
];

export const lineItemsInputs = ({ values, setFieldValue, taxCountry }) => [
  {
    name: 'tempItem.descriptionEn',
    type: 'text',
    label: 'Goods or service description',
    id: 'descriptionEn',
    disabled: false,
    hide: false,
    required: true,
    maxlength: FIELDS_MAX_LENGTH.description,
  },
  {
    name: 'tempItem.unitPrice',
    type: 'text',
    label: 'Unit price (EUD)',
    id: 'unitPrice',
    disabled: false,
    hide: false,
    required: true,
    onBlur: () => onValuesBlur({ item: values.tempItem, setFieldValue }),
  },
  {
    name: 'tempItem.quantity',
    type: 'text',
    label: 'Quantity',
    id: 'quantity',
    disabled: false,
    hide: false,
    required: true,
    onBlur: () => onValuesBlur({ item: values.tempItem, setFieldValue }),
  },
  {
    name: 'tempItem.discountPercentage',
    type: 'text',
    label: 'Discount or rebate percentage',
    id: 'discountPercentage',
    disabled: values?.tempItem?.discountAmount > 0,
    hide: false,
    required: false,
    onBlur: () => onValuesBlur({ item: values.tempItem, setFieldValue }),
  },
  {
    name: 'tempItem.discountAmount',
    type: 'text',
    label: 'Discount or rebate amount (EUD)',
    id: 'discountAmount',
    disabled: values?.tempItem?.discountPercentage > 0,
    hide: false,
    required: false,
    onBlur: () => onValuesBlur({ item: values.tempItem, setFieldValue }),
  },
  {
    name: 'tempItem.vatRateType',
    type: 'select',
    label: 'Tax code for VAT',
    id: 'vatRateType',
    disabled: false,
    hide: false,
    required: true,
    options: getTaxTypes(taxCountry, 'en'),
    onChange: (e) =>
      onVatRateChange(e, {
        setFieldValue,
        item: values.tempItem,
        vatTypes: getTaxTypes(taxCountry, 'en'),
      }),
  },
  {
    name: 'tempItem.vatRate',
    type: 'text',
    label: 'VAT rate',
    id: 'vatRate',
    disabled: true,
    hide: false,
    required: false,
  },
  {
    name: 'tempItem.vatAmount',
    type: 'text',
    label: 'VAT amount (EUD)',
    id: 'vatAmount',
    disabled: true,
    hide: false,
    required: false,
  },
];

export const paymentTermsInputs = ({ values }) => [
  {
    name: 'paymentMethod',
    type: 'select',
    label: 'Payment Method',
    id: 'paymentMethod',
    disabled: false,
    hide: false,
    required: true,
    options: PAYMENT_METHOD('en'),
  },
  {
    name: 'bankNameEn',
    type: 'text',
    label: 'Bank Name',
    id: 'bankNameEn',
    disabled: false,
    hide: values.paymentMethod !== 'BANK_TRANSFER',
    required: true,
    maxlength: FIELDS_MAX_LENGTH.bankName,
  },
  {
    name: 'accountNumber',
    type: 'text',
    label: 'Account Number',
    id: 'accountNumber',
    disabled: false,
    hide: values.paymentMethod !== 'BANK_TRANSFER',
    required: true,
    maxlength: FIELDS_MAX_LENGTH.accountNumber,
  },
  {
    name: 'paymentTermsEn',
    type: 'text',
    label: 'Payment Terms',
    id: 'paymentTermsEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.paymentTerms,
  },
];

export const referenceInputs = ({ previousInvoices, setFieldValue }) => [
  {
    name: 'originalInvoiceReference',
    type: 'select',
    label: 'Reference to original invoice(s) for credit/debit note',
    id: 'originalInvoiceReference',
    disabled: false,
    hide: false,
    required: true,
    options: PREVIOUS_INVOICES({ previousInvoices }),
    onChange: (e) =>
      setOriginalInvoiceGross({ e, previousInvoices, setFieldValue }),
  },
  {
    name: 'originalGrossAmount',
    type: 'text',
    label: 'Gross amount of the referenced invoice',
    id: 'originalGrossAmount',
    disabled: true,
    hide: false,
    required: false,
  },
  {
    name: 'noteReasonOfIssuance',
    type: 'select',
    label: 'Reason for credit/debit note issuance per VAT regulations',
    id: 'noteReasonOfIssuance',
    disabled: false,
    hide: false,
    required: true,
    options: NOTE_ISSUANCE_REASONS('en'),
  },
];

export const notesInputs = () => [
  {
    name: 'specialTaxTreatmentEn',
    type: 'text',
    label: 'Special Tax Treatment',
    id: 'specialTaxTreatmentEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.specialTaxTreatment,
  },
  {
    name: 'notesEn',
    type: 'textarea',
    label: 'Notes',
    id: 'notesEn',
    disabled: false,
    hide: false,
    required: false,
    maxlength: FIELDS_MAX_LENGTH.notes,
  },
];
