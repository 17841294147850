import React, { useState, useEffect } from 'react';
import TaxReturn from './TaxReturn';
import { useTranslation } from 'react-i18next';
import * as api from '../../services/apis';
import { useCustomContext } from '../../context/customContext';

const TaxReturnContainer = ({ ...restProps }) => {
  const [returns, setReturns] = useState([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const { user } = useCustomContext();

  const getTaxReturnList = (taxNumber) => {
    api
      .getTaxReturnsList(taxNumber)
      .then((resp) => {
        setReturns(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      getTaxReturnList(user.tax_number);
    }
  }, [user]);

  return <TaxReturn {...restProps} t={t} loading={loading} returns={returns} />;
};

export default React.memo(TaxReturnContainer);
