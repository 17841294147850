import React from 'react';
import moment from 'moment';
import { getTaxPeriod } from '../../helpers/taxReturnHelpers';

export const ROUTES = [
  {
    label: 'Display/Submit Returns',
    link: '',
  },
  {
    label: 'tax_return',
    link: 'tax-return',
  },
  {
    label: 'Indirect Taxes - Value Added Tax',
    link: '/',
  },
];

export const PERIODS = {
  Monthly: 'Month',
  Quarterly: 'Quarter',
  Annually: 'Year',
};

export const COLUMNS = ({ continueButton }) => [
  {
    label: 'taxReturnList.return_type',
    body: (rowData) => 'Value Added Tax - ' + rowData.periodType,
    sortable: false,
  },
  {
    label: 'taxReturnList.return_period',
    body: (rowData) => `${rowData.dateFrom} to ${rowData.dateTo}`,
    sortable: false,
  },
  {
    label: 'taxReturnList.reference_number',
    value: 'referenceNumber',
    sortable: false,
  },
  {
    label: 'taxReturnList.tax_period',
    body: (rowData) =>
      getTaxPeriod({
        periodType: rowData.periodType,
        count: rowData.periodCount,
        dateFrom: rowData.dateFrom,
      }),
    sortable: false,
  },
  {
    label: 'taxReturnList.due_date',
    body: (rowData) => moment(rowData.dueDate).format('DD-MM-YYYY'),
    sortable: false,
  },
  {
    label: 'taxReturnList.status',
    body: (rowData) =>
      rowData.approved ? (
        <p className="text-success">{'Billed'}</p>
      ) : (
        <p className="text-danger">{'To be filled'}</p>
      ),
    sortable: false,
  },
  {
    label: '',
    body: (rowData) => continueButton(rowData),
  },
];
