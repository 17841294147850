import React from 'react';
import cn from 'classnames';

import styles from './Input.styles.scss';

const Input = ({
  className,
  fieldClassName,
  endAdornmentClassName,
  startAdornmentClassName,
  disabled,
  multiline,
  hasError,
  inputRef,
  startAdornment,
  endAdornment,
  value,
  maxlength,
  ...restProps
}) => {
  const Component = multiline ? 'textarea' : 'input';

  return (
    <div
      className={cn(
        styles.input,
        { [styles.inputDisabled]: disabled },
        className,
      )}
    >
      {startAdornment && (
        <div className={cn(styles.startAdornment, startAdornmentClassName)}>
          {startAdornment}
        </div>
      )}
      <Component
        {...restProps}
        ref={inputRef}
        maxLength={maxlength}
        className={cn(
          styles.field,
          {
            [styles.hasError]: hasError,
            [styles.multiline]: multiline,
            [styles.withStartAdorment]: !!startAdornment,
            [styles.withEndAdorment]: !!endAdornment,
          },
          fieldClassName,
        )}
        value={value || ''}
        disabled={disabled}
      />
      {endAdornment && (
        <div className={cn(styles.endAdornment, endAdornmentClassName)}>
          {endAdornment}
        </div>
      )}
    </div>
  );
};

export default React.memo(Input);
