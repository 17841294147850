import React from 'react';
import { Dashboard } from '../../../../components/Dashboard';
import { PAGES, ROUTES } from './InnerDashboard.config';
import { useTranslation } from 'react-i18next';

const InnerDashboard = ({ type }) => {
  const { t } = useTranslation();

  return (
    <Dashboard
      isEInvoicing={true}
      pages={PAGES(type)}
      routes={ROUTES(type)}
      title={t('generate_' + type + '_title')}
      withBreadcrumb={true}
      tileId={'inner_dashboard'}
    />
  );
};

export default React.memo(InnerDashboard);
