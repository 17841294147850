import React from 'react';
import LineItemsTable from './LineItemsTable';

const LineItemsTableContainer = ({ ...props }) => {
  const handleDeleteItemClick = (itemId, { values, setFieldValue }) => {
    const newItems = values.items?.filter((item) => item.id !== itemId);
    setFieldValue('items', newItems);
  };

  return (
    <LineItemsTable {...props} onDeleteItemClick={handleDeleteItemClick} />
  );
};

export default React.memo(LineItemsTableContainer);
