import { initialValues } from './Filters.formConfig';
import moment from 'moment';

export const convertDataToForm = (data) => {
  if (!data || data.length === 0) {
    return initialValues;
  }

  const search = data.find((filter) => filter.type === 'search');
  const onboardingDateFrom = data.find(
    (filter) => filter.type === 'onboardingDateFrom',
  );
  const onboardingDateTo = data.find(
    (filter) => filter.type === 'onboardingDateTo',
  );
  const certificateExpiryDateFrom = data.find(
    (filter) => filter.type === 'certificateExpiryDateFrom',
  );
  const certificateExpiryDateTo = data.find(
    (filter) => filter.type === 'certificateExpiryDateTo',
  );
  const revocationDateFrom = data.find(
    (filter) => filter.type === 'revocationDateFrom',
  );
  const revocationDateTo = data.find(
    (filter) => filter.type === 'revocationDateTo',
  );

  return {
    search: search?.value || '',
    onboardingDateFrom: onboardingDateFrom?.value || '',
    onboardingDateTo: onboardingDateTo?.value || '',
    certificateExpiryDateFrom: certificateExpiryDateFrom?.value || '',
    certificateExpiryDateTo: certificateExpiryDateTo?.value || '',
    revocationDateFrom: revocationDateFrom?.value || '',
    revocationDateTo: revocationDateTo?.value || '',
  };
};

export const filterData = (filter, originalData) => {
  let filteredData = [...originalData];
  if (filter.onboardingDateFrom) {
    filteredData = filteredData.filter((ele) =>
      moment(ele.onboardingDate, 'YYYY-MM-DD hh:mm:ss').isSameOrAfter(
        moment(filter.onboardingDateFrom, 'DD/MM/YYYY'),
      ),
    );
  }
  if (filter.onboardingDateTo) {
    filteredData = filteredData.filter((ele) =>
      moment(ele.onboardingDate, 'YYYY-MM-DD hh:mm:ss').isSameOrBefore(
        moment(filter.onboardingDateTo, 'DD/MM/YYYY'),
      ),
    );
  }

  if (filter.certificateExpiryDateFrom) {
    filteredData = filteredData.filter((ele) =>
      moment(ele.certificateExpiryDate, 'YYYY-MM-DD hh:mm:ss').isSameOrAfter(
        moment(filter.certificateExpiryDateFrom, 'DD/MM/YYYY'),
      ),
    );
  }
  if (filter.certificateExpiryDateTo) {
    filteredData = filteredData.filter((ele) =>
      moment(ele.certificateExpiryDate, 'YYYY-MM-DD hh:mm:ss').isSameOrBefore(
        moment(filter.certificateExpiryDateTo, 'DD/MM/YYYY'),
      ),
    );
  }

  if (filter.revocationDateFrom) {
    filteredData = filteredData.filter((ele) =>
      moment(ele.revocationDate, 'YYYY-MM-DD hh:mm:ss').isSameOrAfter(
        moment(filter.revocationDateFrom, 'DD/MM/YYYY'),
      ),
    );
  }
  if (filter.revocationDateTo) {
    filteredData = filteredData.filter((ele) =>
      moment(ele.revocationDate, 'YYYY-MM-DD hh:mm:ss').isSameOrBefore(
        moment(filter.revocationDateTo, 'DD/MM/YYYY'),
      ),
    );
  }

  if (filter.search) {
    filteredData = filteredData.filter((ele) => {
      return (
        ele?.commonName?.toLowerCase().indexOf(filter.search.toLowerCase()) !==
          -1 ||
        ele.serialNumber?.toLowerCase().indexOf(filter.search.toLowerCase()) !==
          -1 ||
        ele.vatNumber?.toLowerCase().indexOf(filter.search.toLowerCase()) !==
          -1 ||
        ele.organizationName
          ?.toLowerCase()
          .indexOf(filter.search.toLowerCase()) !== -1 ||
        ele.countryName?.toLowerCase().indexOf(filter.search.toLowerCase()) !==
          -1
      );
    });
  }

  return filteredData;
};
