import React, { useState } from 'react';
import TaxpayerDetails from './TaxpayerDetails';
import { DATA } from './TaxpayerDetails.config';

const TaxpayerDetailsContainer = ({ values, onContinue, isApproved }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <TaxpayerDetails
      onContinue={onContinue}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      values={DATA(values)}
      isApproved={isApproved}
    />
  );
};

export default React.memo(TaxpayerDetailsContainer);
