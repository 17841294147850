import React from 'react';
import { Dashboard } from '../../components/Dashboard';
import { useTranslation } from 'react-i18next';
import { PAGES, ROUTES } from './SmePortal.config';

const SmePortal = () => {
  const { t } = useTranslation();

  return (
    <Dashboard
      isEInvoicing={true}
      pages={PAGES}
      routes={ROUTES}
      title={t('sme')}
      withBreadcrumb={true}
      tileId={'sme_portal'}
    />
  );
};

export default React.memo(SmePortal);
