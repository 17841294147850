import React from 'react';
import { MainLayout } from '../../../../components/MainLayout';
import { PagesHeader } from '../../../../components/shared/PagesHeader';
import { TilesWrapper } from '../../../../components/shared/TilesWrapper';
import { Tile } from '../../../../components/shared/Tile';
import { PageTile } from '../../../../components/PageTile';
import { PAGES, ROUTES } from './ReturnHome.config';
import { randomKey } from '../../../../helpers/common';
import { useTranslation } from 'react-i18next';

const ReturnHome = () => {
  const { t } = useTranslation();

  return (
    <MainLayout eInvoicing={true}>
      <PagesHeader
        title={'Tax Returns'}
        withBreadcrumb={true}
        routes={ROUTES}
        isTaxReturn={true}
      />
      <TilesWrapper>
        {PAGES.map((page, i) => (
          <Tile
            className="col-lg-3 col-md-6 col-sm-12 my-5 bg-white"
            key={randomKey()}
            id={'tax_return' + i}
          >
            <PageTile
              redirectUrl={page.redirectUrl}
              iconName={page.iconName}
              title={t(page.title)}
              buttonName={page.buttonName}
              titleClassName={'mx-5'}
            />
          </Tile>
        ))}
      </TilesWrapper>
    </MainLayout>
  );
};

export default React.memo(ReturnHome);
