import React from 'react';
import { Tile } from '../../../../components/shared/Tile';
import { OtpInput } from '../OtpInput';
import { CODE_LENGTH } from '../OtpInput/OtpInput.config';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/shared/Button';
import cn from 'classnames';
import styles from './OTPs.styles.scss';
import { randomKey } from '../../../../helpers/common';

const OTPs = ({ code, copyText }) => {
  const { t } = useTranslation();

  const multipleOTPs = code.length > 1;

  return (
    <Tile
      className={cn(
        styles.otp,
        'py-5',
        multipleOTPs
          ? 'col-lg-4 col-sm-12 p-4 mb-5'
          : 'col-lg-4 col-md-6 col-sm-12 mb-5',
      )}
    >
      <>
        {code.map((otp) => (
          <OtpInput
            key={randomKey()}
            codeLength={CODE_LENGTH}
            code={otp}
            multipleOTPs={multipleOTPs}
            className="w-100 mb-4"
            copyText={copyText}
          />
        ))}
        {!multipleOTPs && (
          <Button
            className={cn(styles.copyBtn, 'mt-4')}
            variant={'outlined'}
            onClick={() => copyText(code[0])}
            disabled={code[0] === ''}
            id="copy_otp_btn"
          >
            {t('copy_code')}
          </Button>
        )}
      </>
    </Tile>
  );
};

export default React.memo(OTPs);
