import React from 'react';
import styles from './LanguageSelect.styles.scss';
import cn from 'classnames';

const LanguageSelect = ({ onLangClick, currentLanguage, className }) => {
  return (
    <p
      className={cn(styles.changeLang, className)}
      onClick={
        currentLanguage === 'en'
          ? () => onLangClick('ar')
          : () => onLangClick('en')
      }
    >
      {currentLanguage === 'en' ? 'العربية' : 'English'}
    </p>
  );
};

export default React.memo(LanguageSelect);
