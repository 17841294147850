export const PAGES = [
  //   {
  //     title: 'solution_certificate',
  //     iconName: 'solutionCertificate',
  //     buttonName: 'Know More',
  //     redirectUrl: 'solution-certificate',
  //   },
  {
    title: 'initiate_new_registration',
    iconName: 'newReg',
    buttonName: 'Register',
    redirectUrl: 'initiate-new-registration',
  },
  {
    title: 'manage_existing_solution',
    iconName: 'manage',
    buttonName: 'Manage',
    redirectUrl: 'manage-existing-solution',
  },
];

export const ROUTES = [
  {
    label: 'solution_registration',
    link: '',
  },
  {
    label: 'breadcrumb_e_invoicing',
    link: 'e-invoicing',
  },
];
