import React from 'react';
import OtpInput from './OtpInput';
import { useTranslation } from 'react-i18next';

const OtpInputContainer = ({
  codeLength,
  resetTrigger,
  onChange,
  code,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <OtpInput
      {...restProps}
      currentCodeLength={codeLength}
      value={code}
      t={t}
    />
  );
};

export default React.memo(OtpInputContainer);
