import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import App from './App';

const AppContainer = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const classList = document.body.classList;

    classList.remove('en');
    classList.remove('ar');

    classList.add(i18n.language);

    return () => {
      classList.remove(i18n.language);
    };
  }, [i18n.language]);

  return <App />;
};

export default React.memo(AppContainer);
