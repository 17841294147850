import { INVOICE_TYPE_LABELS } from '../../../../config/invoice/constants';
import moment from 'moment';
import { getRegistersVatRateType } from '../../../../helpers/taxReturnHelpers';

export const ROUTES = [
  {
    label: 'sales_register',
    link: '',
  },
  {
    label: 'tax_return',
    link: 'tax-return',
  },
  {
    label: 'Indirect Taxes - Value Added Tax',
    link: '/',
  },
];

export const COLUMNS = [
  {
    label: 'registersList.invoiceType',
    body: (rowData) => INVOICE_TYPE_LABELS('en')[rowData.invoiceType],
    sortable: false,
  },
  {
    label: 'registersList.invoiceNumber',
    value: 'invoiceNumber',
    sortable: false,
  },
  {
    label: 'registersList.issueDate',
    body: (rowData) => moment(rowData.issueDate).format('DD-MM-YYYY'),
    sortable: false,
  },
  {
    label: 'registersList.sellerName',
    value: 'sellerName',
    sortable: false,
  },
  {
    label: 'registersList.sellerTrn',
    value: 'sellerTrn',
    sortable: false,
  },
  {
    label: 'registersList.taxableAmount',
    body: (rowData) => rowData.taxableAmount.toLocaleString(),
    sortable: false,
  },
  {
    label: 'registersList.vatRate',
    body: (rowData) =>
      getRegistersVatRateType(
        rowData.vatRateType,
        rowData.taxExemptionReason,
        rowData.vatRate,
      ),
    sortable: false,
  },
  {
    label: 'registersList.vatAmount',
    value: 'vatAmount',
    sortable: false,
  },
];
