import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { COLUMNS } from '../../InvoiceList.config';
import { useTranslation } from 'react-i18next';
import styles from './InvoicesTable.styles.scss';

const InvoicesTable = ({ invoices, getActions, renderStatus, loading }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <DataTable
        value={invoices}
        className="border-rounded my-5"
        style={{ boxShadow: '0px 0px 10px #00000017' }}
        loading={loading}
      >
        {COLUMNS.map((column) => (
          <Column
            key={column.label}
            field={column.value}
            header={t(column.label)}
            sortable={column.sortable}
            body={column.body}
          />
        ))}
        <Column
          field="invoiceStatus"
          header={t('invoice_list.invoice_status')}
          body={(rowData) => {
            return renderStatus({ status: rowData.invoiceStatus, t });
          }}
        />
        <Column
          field="action"
          header=""
          body={(rowData) => {
            return getActions(rowData.invoiceStatus, rowData);
          }}
        />
      </DataTable>
    </div>
  );
};

export default React.memo(InvoicesTable);
