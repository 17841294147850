import React from 'react';
import Header from './Header';
import { useCustomContext } from '../../context/customContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';

const HeaderContainer = () => {
  const { user } = useCustomContext();

  const { keycloak } = useKeycloak();

  const history = useNavigate();
  const { i18n } = useTranslation();

  const handleHomeClick = () => {
    history(`/${i18n.language}/`, { replace: true });
  };

  const logout = () => {
    keycloak?.logout();
  };

  return (
    <Header
      t={i18n.t}
      language={i18n.language}
      user={user}
      onHomeClick={handleHomeClick}
      logout={logout}
    />
  );
};

export default React.memo(HeaderContainer);
