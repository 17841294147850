import React, { useState } from 'react';
import Tile from './Tile';

const TileContainer = ({ id, ...props }) => {
  const [isHovered, setHovered] = useState({ hovered: false, elementId: null });

  const handleHover = (e) => {
    return e.type === 'mouseover'
      ? setHovered({
          hovered: true,
          elementId: e?.target?.parentElement?.parentElement?.parentElement.id,
        })
      : setHovered({ hovered: false, elementId: null });
  };
  const hoveredStyle = () => {
    const { hovered, elementId } = isHovered;
    return hovered && elementId === id;
  };

  return (
    <Tile
      {...props}
      onHover={handleHover}
      ishovered={isHovered}
      hoveredStyle={hoveredStyle}
      id={id}
    />
  );
};

export default React.memo(TileContainer);
