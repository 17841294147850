import React from 'react';
import styles from './ViewInvoice.styles.scss';
import { MainLayout } from '../../../../components/MainLayout';
import { PagesHeader } from '../../../../components/shared/PagesHeader';
import { ROUTES } from './ViewInvoice.config';
import { viewObject } from './ViewInvoice.config';
import cn from 'classnames';
import { Button } from '../../../../components/shared/Button';
import { INVOICE_TYPE_LABELS } from '../../../../config/invoice/constants';
import { ViewInput } from './components/ViewInput';
import { ViewSection } from './components/ViewSection';

const ViewInvoice = ({
  loading,
  onDownloadPdfClick,
  onDownloadXmlClick,
  invoice,
  type,
}) => {
  return (
    <MainLayout
      loading={loading || !invoice}
      className={styles.container}
      eInvoicing={true}
    >
      <PagesHeader
        routes={ROUTES()}
        withBreadcrumb={true}
        title={INVOICE_TYPE_LABELS('en')[type]}
      />
      <div>
        {invoice &&
          viewObject(invoice).map((block, blockIdx) =>
            block.image ? (
              <div className={cn('row', styles.block)} key={block.key}>
                <div className="col-10">
                  {block.sections.map((section) => (
                    <div className="row" key={section.key}>
                      {section.title && (
                        <h4 className={styles.sectionTitle}>{section.title}</h4>
                      )}
                      <div className={cn('row', styles.section)}>
                        {section?.inputs?.map((input, i) => (
                          <ViewInput
                            key={input.label}
                            input={input}
                            index={i}
                            styles={styles}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-2">
                  <img src={block.image} />
                </div>
              </div>
            ) : (
              <ViewSection
                block={block}
                blockIdx={blockIdx}
                styles={styles}
                key={block.key}
              />
            ),
          )}
      </div>
      <div className={styles.buttons}>
        <Button
          onClick={onDownloadXmlClick}
          className={'col-2 mx-3'}
          variant={'outlined'}
          id="download_xml_btn"
        >
          Download XML
        </Button>
        <Button
          id="download_pdf_btn"
          onClick={onDownloadPdfClick}
          className={'col-2 mx-3'}
        >
          Download PDF
        </Button>
      </div>
    </MainLayout>
  );
};

export default React.memo(ViewInvoice);
