import React from 'react';
import styles from './ReturnsListTable.styles.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { COLUMNS } from '../../TaxReturn.config';

const ReturnsListTable = ({ loading, returns, t, continueButton }) => {
  return (
    <div className={styles.container}>
      <DataTable
        value={returns}
        className="border-rounded my-5"
        loading={loading}
      >
        {COLUMNS({ continueButton }).map((column) => (
          <Column
            key={column.label}
            field={column.value}
            header={t(column.label)}
            sortable={column.sortable}
            body={column.body}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default React.memo(ReturnsListTable);
