import React, { useState } from 'react';
import LanguageSelect from './LanguageSelect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const LanguageSelectContainer = (props) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const languages = {
    ar: 'عربي',
    en: 'English',
  };

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [languageLabel, setLanguageLabel] = useState(languages[i18n.language]);

  const handleChangeLanguageClick = (lang) => {
    if (lang !== currentLanguage) {
      const pathNameWithoutLang = location.pathname
        .split('/')
        .filter((pathChunk) => pathChunk !== 'en' && pathChunk !== 'ar')
        .join('/');

      i18n.changeLanguage(lang);
      history(`/${lang}${pathNameWithoutLang}`, { replace: true });
      setCurrentLanguage(lang);
      setLanguageLabel(languages[lang]);
    }
    return;
  };

  return (
    <LanguageSelect
      {...props}
      currentLanguage={currentLanguage}
      onLangClick={handleChangeLanguageClick}
      languageLabel={languageLabel}
    />
  );
};

export default React.memo(LanguageSelectContainer);
