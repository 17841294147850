import React from 'react';
import { INSTRUCTIONS_DATA } from '../../TaxReturnDetails.config';
import styles from './Instructions.styles.scss';
import { randomKey } from '../../../../../helpers/common';
import { Icon } from '../../../../../components/shared/Icon';
import cn from 'classnames';
import { Button } from '../../../../../components/shared/Button';
import { Disclaimer } from '../../../../../components/shared/Disclaimer';

const Instructions = ({ isChecked, onContinue, onCancel, setIsChecked }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{INSTRUCTIONS_DATA.title}</p>
      <ul>
        {INSTRUCTIONS_DATA.instructions.map((item) => (
          <li className="mt-3" key={randomKey()}>
            <Icon className={'mx-3'} name="greenCheck" />
            <span>
              {item.label}
              {item.link && (
                <a className={styles.link} href={item.link}>
                  {item.linkText}
                </a>
              )}
            </span>
          </li>
        ))}
      </ul>

      <Disclaimer
        disclaimerText={INSTRUCTIONS_DATA.declaration}
        setIsChecked={setIsChecked}
      />
      {isChecked && (
        <div className={styles.actionsContainer}>
          <a
            className={('mx-3', styles.cancelLink)}
            onClick={onCancel}
            href="/tax-return"
          >
            Cancel
          </a>
          <Button
            className={cn('mx-5 col-2', styles.continueBtn)}
            onClick={onContinue}
          >
            Step 2
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(Instructions);
