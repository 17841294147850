import React from 'react';
import styles from './Tile.styles.scss';
import cn from 'classnames';

const Tile = ({
  className,
  children,
  id,
  hoveredStyle,
  ishovered,
  onHover,
}) => {
  return (
    <div className={cn(styles.container, className)} id={id}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          ishovered,
          tileid: id,
          onHover,
        });
      })}
    </div>
  );
};

export default React.memo(Tile);
