import React from 'react';
import { Icon } from '../../../../../../../../components/shared/Icon';
import styles from './DownloadButton.styles.scss';
import cn from 'classnames';

const DownloadButton = ({ onDownload }) => {
  return (
    <Icon
      onClick={onDownload}
      className={cn('mx-2', styles.button)}
      name={'downloadInvoice'}
    />
  );
};

export default React.memo(DownloadButton);
