import React from 'react';
// import { CTable } from '@coreui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LINE_ITEMS_COLUMNS } from './LineItemsTable.config';
import styles from './LineItemsTable.styles.scss';
import { Icon } from '../../../../../../components/shared/Icon';
import cn from 'classnames';

const LineItemsTable = ({
  values,
  onDeleteItemClick,
  // onEditItemClick,
  setFieldValue,
}) => {
  return (
    <div className={styles.container}>
      <DataTable
        scrollable
        scrollHeight="400px"
        value={values.items}
        tableStyle={{ minWidth: '50rem' }}
        className="line-items-datatable"
      >
        {LINE_ITEMS_COLUMNS.map((col, i) => (
          <Column
            key={col.key}
            field={col.key}
            header={col.label}
            minWidth={'50rem'}
          />
        ))}
        <Column
          field="action"
          header=""
          body={(item, itemNode) => {
            return (
              <div className="d-flex">
                {/* <span
                  className={cn('mx-2 d-flex', styles.actionButtons)}
                  onClick={() =>
                    onEditItemClick(
                      { item, itemIndex: itemNode.rowIndex },
                      { values, setFieldValue },
                    )
                  }
                >
                  <Icon name={'edit'} />
                </span> */}
                <span
                  className={cn('mx-2 d-flex', styles.actionButtons)}
                  onClick={() =>
                    onDeleteItemClick(item.id, { values, setFieldValue })
                  }
                >
                  <Icon name={'delete'} />
                </span>
              </div>
            );
          }}
        />
      </DataTable>
    </div>
  );
};

export default React.memo(LineItemsTable);
