import React, { useState, useEffect } from 'react';
import { STEPS } from './CreateInvoice.config';
import CreateInvoice from './CreateInvoice';
import * as api from '../../../../services/apis';
import { useCustomContext } from '../../../../context/customContext';
import { getCreateInvoiceInitialValues } from './formConfig/initialValues';
import { showToast } from '../../../../helpers/showToast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { convertFormDataToApi } from '../../../../helpers/invoiceHelpers';
import { scrollToTop } from '../../../../helpers/common';

const CreateInvoiceContainer = ({ category, type, taxCountry, ...props }) => {
  const [invoiceReferenceNumber, setInvoiceReferenceNumber] = useState('');
  const { user } = useCustomContext();
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const initialForm = getCreateInvoiceInitialValues({
    user,
    invoiceReferenceNumber,
    type,
    category,
  });

  const steps = STEPS({ type, formObject: initialForm });

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(steps[activeIndex]);
  const [previousInvoices, setPreviousInvoices] = useState([]);
  const [invoiceCount, setInvoicesCount] = useState(0);
  const [invoiceLimit, setInvoicesLimit] = useState(0);
  const [formObject, setFormObject] = useState(initialForm);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      let invoiceType =
        category === 'standard' ? 'STANDARD_TAX' : 'SIMPLIFIED_TAX';

      api.getInvoiceOnboardData(user.tax_number, invoiceType).then((resp) => {
        setPreviousInvoices(resp.data?.previousInvoices);
        setInvoiceReferenceNumber(resp.data?.nextIRN);
        setInvoicesCount(resp.data?.invoiceCount);
        setInvoicesLimit(resp.data?.invoicesCounterLimit);
      });
    }
  }, [user]);

  const handleSubmitFormClick = (values, invoiceType) => {
    if (!values.items.length) {
      showToast({
        data: {
          variant: 'error',
          text: t('create_invoice_validation.add_an_item'),
        },
      });
      return;
    }

    const data = convertFormDataToApi({
      data: values,
      type,
      category,
      taxCountry,
    });

    setLoading(true);

    api
      .generateInvoice(data, user.tax_number)
      .then((response) => {
        setLoading(false);

        history(
          `/${i18n.language}/sme/view-list/view-invoice/${response.data?.uniqueReferenceIdentifier}/${data.invoiceType}`,
        );
      })
      .catch(({ error }) => {
        showToast({
          data: {
            variant: 'error',
            text: error,
          },
        });

        setLoading(false);
      });
    // history(`/${i18n.language}/sme/view-list`);
  };

  const handleStepSubmit = (object) => {
    if (
      activeStep.value === 'sellerIdentification' ||
      activeStep.value === 'sellerAddress'
    ) {
      setFormObject({
        ...formObject,
        seller: {
          ...formObject.seller,
          ...object,
        },
      });
    } else if (
      activeStep.value === 'buyerIdentification' ||
      activeStep.value === 'buyerAddress'
    ) {
      setFormObject({
        ...formObject,
        buyer: {
          ...formObject.buyer,
          ...object,
        },
      });
    } else if (activeStep.value === 'lineItems') {
      if (object.items.length >= 1) {
        setFormObject({
          ...formObject,
          items: [...object.items],
        });
      } else {
        scrollToTop();
        return showToast({
          data: {
            variant: 'error',
            text: 'You should add at least one item',
          },
        });
      }
    } else if (activeStep.value === 'paymentTerms') {
      setFormObject({
        ...formObject,
        paymentTerms: {
          ...formObject.paymentTerms,
          ...object,
        },
      });
    } else {
      setFormObject({
        ...formObject,
        ...object,
      });
    }

    const nextIndex = activeIndex + 1;

    if (nextIndex < steps.length) {
      setActiveIndex(nextIndex);
      setActiveStep(steps[nextIndex]);
      scrollToTop();
    } else {
      handleSubmitFormClick(formObject, type);
    }
  };

  const resetInvoice = () => {
    setActiveIndex(0);
    setActiveStep(steps[0]);
    setFormObject(initialForm);
    scrollToTop();
  };

  return (
    <CreateInvoice
      taxCountry={taxCountry}
      {...props}
      type={type}
      activeStep={activeStep}
      handleStepSubmit={handleStepSubmit}
      invoiceCount={invoiceCount}
      invoiceLimit={invoiceLimit}
      formObject={initialForm}
      previousInvoices={previousInvoices}
      loading={loading}
      resetInvoice={resetInvoice}
    />
  );
};

export default React.memo(CreateInvoiceContainer);
