import React from 'react';
import { Header } from '../Header';
import styles from './MainLayout.styles.scss';
import { Loader } from '../shared/Loading';
import { Footer } from '../Footer';
import { PrivateRoute } from '../PrivateRoute';
import cn from 'classnames';

const MainLayout = ({
  children,
  loading,
  eInvoicing,
  className,
  bannerClassName,
}) => {
  return (
    <PrivateRoute>
      <div className={cn(styles.layout)}>
        <Header />
        <div className={cn(styles.inner, className)}>
          <Loader active={loading}>
            {eInvoicing ? (
              <div className={cn(styles.withBanner, bannerClassName)}>
                {children}
              </div>
            ) : (
              children
            )}
          </Loader>
        </div>
        <Footer />
      </div>
    </PrivateRoute>
  );
};

export default React.memo(MainLayout);
