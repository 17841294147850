import React from 'react';
import { Dialog } from 'primereact/dialog';
import styles from './SuccessModal.styles.scss';
import cn from 'classnames';
import { Icon } from '../../../../../components/shared/Icon';
import { useNavigate } from 'react-router-dom';

const SuccessModal = ({ visible, setVisible, successResponse }) => {
  const history = useNavigate();

  const onHide = () => {
    setVisible(false);
    history(-1);
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      style={{ width: '75vw', borderRadius: 0, height: '30vw' }}
      //   showHeader={false}
      header="Acknowledgement"
    >
      <div className="p-4">
        {/* <p className={styles.acknowledgement}>Acknowledgement</p> */}
        <p className={styles.successAck}>
          ZATCA acknowledges the receipt of your tax return successfully for
        </p>
        <div className="row">
          <div className={cn('col-4', styles.inputs)}>
            <p className="col-4">Name</p>
            <input
              className="col-8"
              type="text"
              disabled
              value={successResponse.name}
            />
          </div>
          <div className={cn('col-4', styles.inputs)}>
            <p className="col-4">Return Reference Number</p>
            <input
              type="text"
              disabled
              value={successResponse.referenceNumber}
              className="col-8"
            />
          </div>
          <div className={cn('col-4', styles.inputs)}>
            <p className="col-4">Taxable period</p>
            <input
              className="col-8"
              type="text"
              disabled
              value={successResponse.taxablePeriod}
            />
          </div>
          <div className={cn('col-4', styles.inputs)}>
            <p className="col-4">Receipt Date</p>
            <input
              className="col-8"
              type="text"
              disabled
              value={successResponse.receiptDate}
            />
          </div>
        </div>
        <div className={styles.notes}>
          <ul className="mt-5">
            <li className="mt-3">
              <Icon className={'mx-3'} name="greenCheck" />
              <span>
                For further information, please contact helpdesk - 19993
              </span>
            </li>
            <li className="mt-3">
              <Icon className={'mx-3'} name="greenCheck" />
              <span>
                ZATCA reserves the right to investigate your return form further
              </span>
            </li>
          </ul>
        </div>
      </div>
    </Dialog>
  );
};

export default React.memo(SuccessModal);
