import React from 'react';
import { DayPicker } from 'react-day-picker';

const DatePicker = ({ className, toYear, disabledDays, ...restProps }) => (
  <>
    <DayPicker
      {...restProps}
      mode="single"
      showOutsideDays
      disabled={disabledDays}
      captionLayout="dropdown"
      fromYear={2000}
      toYear={toYear || new Date().getFullYear()}
    />
  </>
);

export default React.memo(DatePicker);
