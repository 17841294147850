import React from 'react';
import styles from './InputContainer.styles.scss';
import cn from 'classnames';

const InputContainer = ({ label, value, name, disabled, id, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <p className={cn(styles.label, 'col-4')}>{label}</p>
      <div className={cn(styles.inputContainer, 'col-8')}>
        <input
          type="text"
          className={styles.input}
          value={value}
          name={name}
          disabled={disabled}
          id={id}
        />
      </div>
    </div>
  );
};

export default React.memo(InputContainer);
