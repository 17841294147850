export const handleAdjustmentBlur = ({ e, setFieldValue, values }) => {
  console.log({ e });
  const standardAdj = +values?.newSales?.standardAdj || 0.0;
  const standardRateTaxAmount = +values?.sales?.standardRateTaxAmount || 0.0;
  const reducedAdj = +values?.newSales?.reducedAdj || 0.0;
  const reducedRateTaxAmount = +values?.sales?.reducedRateTaxAmount || 0.0;
  const stateTaxableSalesAdj = +values?.newSales?.stateTaxableSalesAdj || 0.0;
  const zeroRatedAdj = +values?.newSales?.zeroRatedAdj || 0.0;
  const exportsAdj = +values?.newSales?.exportsAdj || 0.0;
  const exemptAdj = +values?.newSales?.exemptAdj || 0.0;

  const standardRatedAdj = +values?.newPurchases?.standardRatedAdj || 0.0;
  const standardRateTaxAmountPurchases =
    +values?.purchases?.standardRateTaxAmount || 0.0;
  const reducedRateAdj = +values?.newPurchases?.reducedRateAdj || 0.0;
  const reducedRateTaxAmountPurchase =
    +values?.purchases?.reducedRateTaxAmount || 0.0;
  const importsSubjectToVatAdj =
    +values?.newPurchases?.importsSubjectToVatAdj || 0.0;
  const importsSubjectToVatTaxAmount =
    +values?.purchases?.importsSubjectToVatTaxAmount || 0.0;
  const reducedImportsSubjectToVatAdj =
    +values?.newPurchases?.reducedImportsSubjectToVatAdj || 0.0;
  const reducedImportsSubjectToVatTaxAmount =
    +values?.purchases?.reducedImportsSubjectToVatTaxAmount || 0.0;
  const reverseImportsAdj = +values?.newPurchases?.reverseImportsAdj || 0.0;
  const importsReverseChargeTaxAmount =
    +values?.purchases?.importsReverseChargeTaxAmount || 0.0;
  const reducedReverseImportsAdj =
    +values?.newPurchases?.reducedReverseImportsAdj || 0.0;
  const reducedImportsReverseChargeTaxAmount =
    +values?.purchases?.reducedImportsReverseChargeTaxAmount || 0.0;
  const zeroRatedAdjPurchase = +values?.newPurchases?.zeroRatedAdj || 0.0;
  const exemptAdjPurchase = +values?.newPurchases?.exemptAdj || 0.0;

  switch (e?.target?.name) {
    case 'newSales.standardAdj': {
      const originalValue = +values?.sales?.standardRatedSales || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((originalValue - value) * 0.15).toFixed(2);
      const totalSales = (
        +value +
        +reducedAdj +
        +stateTaxableSalesAdj +
        +zeroRatedAdj +
        +exportsAdj +
        +exemptAdj
      ).toFixed(2);
      const totalSalesTaxAmount = (+taxAmount + +reducedRateTaxAmount).toFixed(
        2,
      );
      setFieldValue('sales.standardRateTaxAmount', taxAmount);
      setFieldValue('newSales.standardVat', taxAmount);
      setFieldValue('newSales.totalSalesAdj', totalSales);
      setFieldValue('sales.totalSalesTaxAmount', totalSalesTaxAmount);
      setFieldValue('newSales.totalSalesVat', totalSalesTaxAmount);
      break;
    }
    case 'newSales.reducedAdj': {
      const originalValue = +values?.sales?.reducedRate || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((originalValue - value) * 0.05).toFixed(2);
      const totalSales = (
        +value +
        +standardAdj +
        +stateTaxableSalesAdj +
        +zeroRatedAdj +
        +exportsAdj +
        +exemptAdj
      ).toFixed(2);
      const totalSalesTaxAmount = (+taxAmount + +standardRateTaxAmount).toFixed(
        2,
      );
      setFieldValue('sales.reducedRateTaxAmount', taxAmount);
      setFieldValue('newSales.reducedVat', taxAmount);
      setFieldValue('newSales.totalSalesAdj', totalSales);
      setFieldValue('sales.totalSalesTaxAmount', totalSalesTaxAmount);
      setFieldValue('newSales.totalSalesVat', totalSalesTaxAmount);
      break;
    }
    case 'newSales.stateTaxableSalesAdj': {
      const value = +e?.target?.value || 0;
      const totalSales = (
        +value +
        +reducedAdj +
        +standardAdj +
        +zeroRatedAdj +
        +exportsAdj +
        +exemptAdj
      ).toFixed(2);
      setFieldValue('newSales.totalSalesAdj', totalSales);
      break;
    }
    case 'newSales.zeroRatedAdj': {
      const value = e?.target?.value || 0;
      const totalSales = (
        +value +
        +reducedAdj +
        +stateTaxableSalesAdj +
        +standardAdj +
        +exportsAdj +
        +exemptAdj
      ).toFixed(2);
      setFieldValue('newSales.totalSalesAdj', totalSales);
      break;
    }
    case 'newSales.exportsAdj': {
      const value = +e?.target?.value || 0;
      const totalSales = (
        +value +
        +reducedAdj +
        +stateTaxableSalesAdj +
        +zeroRatedAdj +
        +standardAdj +
        +exemptAdj
      ).toFixed(2);
      setFieldValue('newSales.totalSalesAdj', totalSales);
      break;
    }
    case 'newSales.exemptAdj': {
      const value = +e?.target?.value || 0;
      const totalSales = (
        +value +
        +reducedAdj +
        +stateTaxableSalesAdj +
        +zeroRatedAdj +
        +exportsAdj +
        +standardAdj
      ).toFixed(2);
      setFieldValue('newSales.totalSalesAdj', totalSales);
      break;
    }
    case 'newPurchases.standardRatedAdj': {
      const originalValue = +values?.purchases?.standardRatedPurchase || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((originalValue - value) * 0.15).toFixed(2);
      const totalPurchases = (
        +value +
        +reducedRateAdj +
        +importsSubjectToVatAdj +
        +reducedImportsSubjectToVatAdj +
        +reverseImportsAdj +
        +reducedReverseImportsAdj +
        +zeroRatedAdjPurchase +
        +exemptAdjPurchase
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +reducedRateTaxAmountPurchase +
        +importsSubjectToVatTaxAmount +
        +reducedImportsSubjectToVatTaxAmount +
        +importsReverseChargeTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);
      setFieldValue('purchases.standardRateTaxAmount', taxAmount);
      setFieldValue('newPurchases.standardRateTaxAmount', taxAmount);
      setFieldValue('newPurchases.totalPurchasesAdj', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'newPurchases.reducedRateAdj': {
      const originalValue = +values?.purchases?.reducedRate || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((originalValue - value) * 0.05).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedAdj +
        +importsSubjectToVatAdj +
        +reducedImportsSubjectToVatAdj +
        +reverseImportsAdj +
        +reducedReverseImportsAdj +
        +zeroRatedAdjPurchase +
        +exemptAdjPurchase
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmountPurchases +
        +importsSubjectToVatTaxAmount +
        +reducedImportsSubjectToVatTaxAmount +
        +importsReverseChargeTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);
      setFieldValue('purchases.reducedRateTaxAmount', taxAmount);
      setFieldValue('newPurchases.reducedRateTaxAmount', taxAmount);
      setFieldValue('newPurchases.totalPurchasesAdj', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'newPurchases.importsSubjectToVatAdj': {
      const originalValue = +values?.purchases?.importsSubjectToVat || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((originalValue - value) * 0.15).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedAdj +
        +reducedRateAdj +
        +reducedImportsSubjectToVatAdj +
        +reverseImportsAdj +
        +reducedReverseImportsAdj +
        +zeroRatedAdjPurchase +
        +exemptAdjPurchase
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmountPurchases +
        +reducedRateTaxAmountPurchase +
        +reducedImportsSubjectToVatTaxAmount +
        +importsReverseChargeTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);
      setFieldValue('purchases.importsSubjectToVatTaxAmount', taxAmount);
      setFieldValue('newPurchases.importsSubjectToVatTaxAmount', taxAmount);
      setFieldValue('newPurchases.totalPurchasesAdj', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'newPurchases.reducedImportsSubjectToVatAdj': {
      const originalValue = +values?.purchases?.reducedImportsSubjectToVat || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((originalValue - value) * 0.05).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedAdj +
        +reducedRateAdj +
        +importsSubjectToVatAdj +
        +reverseImportsAdj +
        +reducedReverseImportsAdj +
        +zeroRatedAdjPurchase +
        +exemptAdjPurchase
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmountPurchases +
        +reducedRateTaxAmountPurchase +
        +importsSubjectToVatTaxAmount +
        +importsReverseChargeTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);
      setFieldValue('purchases.reducedImportsSubjectToVatTaxAmount', taxAmount);
      setFieldValue(
        'newPurchases.reducedImportsSubjectToVatTaxAmount',
        taxAmount,
      );
      setFieldValue('newPurchases.totalPurchasesAdj', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'newPurchases.reverseImportsAdj': {
      const originalValue = +values?.purchases?.importsReverseCharge || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((originalValue - value) * 0.15).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedAdj +
        +reducedRateAdj +
        +importsSubjectToVatAdj +
        +reducedImportsSubjectToVatAdj +
        +reducedReverseImportsAdj +
        +zeroRatedAdjPurchase +
        +exemptAdjPurchase
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmountPurchases +
        +reducedRateTaxAmountPurchase +
        +importsSubjectToVatTaxAmount +
        +reducedImportsSubjectToVatTaxAmount +
        +reducedImportsReverseChargeTaxAmount
      ).toFixed(2);
      setFieldValue('purchases.importsReverseChargeTaxAmount', taxAmount);
      setFieldValue('newPurchases.reverseImportsTaxAmount', taxAmount);
      setFieldValue('newPurchases.totalPurchasesAdj', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'newPurchases.reducedReverseImportsAdj': {
      const originalValue =
        +values?.purchases?.reducedImportsReverseCharge || 0;
      const value = +e?.target?.value || 0;
      const taxAmount = ((originalValue - value) * 0.05).toFixed(2);
      const totalPurchases = (
        +value +
        +standardRatedAdj +
        +reducedRateAdj +
        +importsSubjectToVatAdj +
        +reducedImportsSubjectToVatAdj +
        +reverseImportsAdj +
        +zeroRatedAdjPurchase +
        +exemptAdjPurchase
      ).toFixed(2);
      const totalPurchasesTaxAmount = (
        +taxAmount +
        +standardRateTaxAmountPurchases +
        +reducedRateTaxAmountPurchase +
        +importsSubjectToVatTaxAmount +
        +reducedImportsSubjectToVatTaxAmount +
        +importsReverseChargeTaxAmount
      ).toFixed(2);
      setFieldValue(
        'purchases.reducedImportsReverseChargeTaxAmount',
        taxAmount,
      );
      setFieldValue('newPurchases.reducedReverseImportsTaxAmount', taxAmount);
      setFieldValue('newPurchases.totalPurchasesAdj', totalPurchases);
      setFieldValue(
        'purchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      setFieldValue(
        'newPurchases.totalPurchasesTaxAmount',
        totalPurchasesTaxAmount,
      );
      break;
    }
    case 'newPurchases.zeroRatedAdj': {
      const value = +e?.target?.value || 0;
      const totalPurchases = (
        +value +
        +standardRatedAdj +
        +reducedRateAdj +
        +importsSubjectToVatAdj +
        +reducedImportsSubjectToVatAdj +
        +reverseImportsAdj +
        +reducedReverseImportsAdj +
        +exemptAdjPurchase
      ).toFixed(2);
      setFieldValue('newPurchases.totalPurchasesAdj', totalPurchases);
      break;
    }
    case 'newPurchases.exemptAdj': {
      const value = +e?.target?.value || 0;
      const totalPurchases = (
        +value +
        +standardRatedAdj +
        +reducedRateAdj +
        +importsSubjectToVatAdj +
        +reducedImportsSubjectToVatAdj +
        +reverseImportsAdj +
        +reducedReverseImportsAdj +
        +zeroRatedAdjPurchase
      ).toFixed(2);
      setFieldValue('newPurchases.totalPurchasesAdj', totalPurchases);
      break;
    }
    case 'correctionFromPreviousAdj': {
      const correctionFromPreviousValue =
        +values?.correctionFromPreviousValue || 0;
      const correctionFromPrevious =
        correctionFromPreviousValue - +e.target.value || 0;
      setFieldValue('correctionFromPrevious', correctionFromPrevious);
      break;
    }
  }

  const correctionFromPrevious = values?.correctionFromPrevious || 0.0;
  const vatCreditCarriedForward = 0.0;
  const totalSales = +values?.sales?.totalSalesTaxAmount || 0;
  const totalPurchases = +values?.purchases?.totalPurchasesTaxAmount || 0;
  const currentTotalVatDue = (totalSales - totalPurchases).toFixed(2);

  const netVatDue = (
    +currentTotalVatDue +
    +correctionFromPrevious -
    +vatCreditCarriedForward
  ).toFixed(2);

  setFieldValue('currentTotalVatDue', currentTotalVatDue);
  setFieldValue('correctionFromPrevious', correctionFromPrevious);
  setFieldValue('vatCreditCarriedForward', vatCreditCarriedForward);
  setFieldValue('netVatDue', netVatDue);
};
