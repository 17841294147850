import React from 'react';
import styles from './Filters.styles.scss';
import { Button } from '../../../../components/shared/Button';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { FormikField } from '../../../../components/FormikField';
import {
  InputField,
  SelectField,
  DatePickerInputField,
} from '../../../../components/FormikFields';

import parse from 'date-fns/parse';
import { validationSchema } from './Filters.formConfig';
import { convertDataToForm } from './Filters.helpers';
import { SELECT_AN_OPTION_LABEL } from '../../../../constants/common';

const Filters = ({ onSubmitFilters, currentFilters, onResetFormClick }) => {
  const OPTIONS = [
    { label: SELECT_AN_OPTION_LABEL, value: '' },
    { label: 'Standard Invoice', value: 'STANDARD_TAX' },
    { label: 'Standard Credit Note', value: 'STANDARD_CREDIT_NOTE' },
    { label: 'Standard Debit Note', value: 'STANDARD_DEBIT_NOTE' },
    { label: 'Simplified Invoice', value: 'SIMPLIFIED_TAX' },
    { label: 'Simplified Credit Note', value: 'SIMPLIFIED_CREDIT_NOTE' },
    { label: 'Simplified Debit Note', value: 'SIMPLIFIED_DEBIT_NOTE' },
  ];

  const { t } = useTranslation();

  return (
    <div className={cn('mt-4', styles.container)}>
      <Formik
        validateOnMount
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={convertDataToForm(currentFilters)}
        onSubmit={onSubmitFilters}
      >
        {({ resetForm, values }) => (
          <Form>
            <div className="row">
              <div className="col-lg-3 col-md-5 col-sm-12 d-flex flex-column">
                <FormikField
                  name="search"
                  component={InputField}
                  label={t('common.search')}
                  componentProps={{
                    className: styles.searchInput,
                    placeholder: 'Search...',
                  }}
                  controlProps={{
                    labelProps: {
                      className: styles.labels,
                    },
                  }}
                />
              </div>
              <div className="col-lg-3 col-md-5 col-sm-12 d-flex flex-column">
                <FormikField
                  name="invoiceType"
                  component={SelectField}
                  componentProps={{
                    className: styles.field,
                    options: OPTIONS,
                  }}
                  controlProps={{
                    labelProps: {
                      className: styles.labels,
                    },
                  }}
                  label={t('invoice_list.invoice_type_discription')}
                />
              </div>
              <div className="col-md-6 col-sm-12 d-flex ">
                <div className="d-flex flex-column col-6">
                  <FormikField
                    name="issueDateFrom"
                    component={DatePickerInputField}
                    label={t('invoice_list.date_from')}
                    componentProps={{
                      className: cn(styles.datepicker, styles.dateFrom),
                    }}
                    controlProps={{
                      labelProps: {
                        className: styles.labels,
                      },
                    }}
                  />
                </div>
                <div className="d-flex flex-column col-6">
                  <FormikField
                    name="issueDateTo"
                    component={DatePickerInputField}
                    componentProps={{
                      disabledDays: values?.issueDateFrom
                        ? [
                            {
                              before: parse(
                                values?.issueDateFrom,
                                'dd/MM/yyyy',
                                new Date(),
                              ),
                            },
                          ]
                        : [],
                      className: cn(styles.datepicker, styles.dateTo),
                    }}
                    label={t('invoice_list.date_to')}
                    controlProps={{
                      labelProps: {
                        className: styles.labels,
                      },
                    }}
                  />
                </div>
              </div>
              <div
                className={cn('col-lg-3 col-sm-12 w-100 mb-5', styles.buttons)}
              >
                <Button
                  id="sme_filter_btn"
                  type="submit"
                  className={cn('col-2 mx-2')}
                >
                  {t('common.filter')}
                </Button>
                <Button
                  onClick={() => onResetFormClick(resetForm)}
                  className={cn('col-2 mx-2', styles.resetButton)}
                  variant={'outlined'}
                  id="sme_reset_btn"
                >
                  {t('common.reset')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(Filters);
