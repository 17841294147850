import React from 'react';
import cn from 'classnames';
import styles from './Toast.styles.scss';

const VARIANTS = {
  success: styles.success,
  warning: styles.warning,
  info: styles.info,
  error: styles.error,
};

const Toast = ({ className, variant, title, text }) => (
  <div className={cn(styles.toast, VARIANTS[variant], className)}>
    <div className={styles.inner}>
      <div className={styles.main}>
        {title && <p className={styles.title}>{title}</p>}
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  </div>
);

export default React.memo(Toast);
