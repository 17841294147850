export const ROUTES = [
  {
    label: 'tax_return_details',
    link: '',
  },
  {
    label: 'Display/Submit Returns',
    link: 'tax-return/display-submit-returns',
  },
  {
    label: 'tax_return',
    link: 'tax-return',
  },
  {
    label: 'Indirect Taxes - Value Added Tax',
    link: '/',
  },
];

export const STEPS = () => [
  {
    label: 'Instructions',
    value: 'instructions',
    withReset: false,
  },
  {
    label: 'Taxpayer Details',
    value: 'taxpayerDetails',
    withReset: false,
  },
  {
    label: 'VAT Return Form',
    value: 'vatReturnForm',
    withReset: false,
  },
  {
    label: '',
    value: 'summary',
    withReset: false,
  },
];

export const INSTRUCTIONS_DATA = {
  title: 'Kindly read the below instructions before completing return form:',
  instructions: [
    {
      label: 'Please fill the requested information for each step.',
    },
    {
      label:
        'The application cannot be successfully submitted until all of the mandatory fields have been completed.',
    },
    {
      label:
        'Taxpayers should ensure that their existing taxpayer details are complete and up to date before filing their VAT returns.',
    },
    {
      label:
        'Please refer to the FAQ section before filling out the return form. ',
      linkText: 'Click here to open FAQ URL',
      link: '#',
    },
  ],
  declaration:
    'I declare that I have read and understood the above instructions.',
};
