export const PAGES = (SHOW_SME) => [
  //   {
  //     title: 'solution_certificate',
  //     iconName: 'solCert',
  //     buttonName: 'Know More',
  //     redirectUrl: 'solution-certificate',
  //   },
  {
    title: 'sme',
    iconName: 'sme',
    buttonName: 'Invoicing',
    redirectUrl: 'sme',
    hide: !SHOW_SME,
  },
  {
    title: 'solution_registration',
    iconName: 'solReg',
    buttonName: 'Register',
    redirectUrl: 'solution-registration',
  },
  {
    title: 'dashboard_and_statistics',
    iconName: 'dashStat',
    buttonName: 'Statistics',
    redirectUrl: 'dashboard',
  },
  {
    title: 'tax_return',
    iconName: 'taxReturn',
    buttonName: 'Tax Return',
    redirectUrl: 'tax-return',
  },
];
export const ROUTES = [
  {
    label: 'breadcrumb_e_invoicing',
    link: '',
  },
];
