import React, { useState } from 'react';
import styles from './Summary.styles.scss';
import { Disclaimer } from '../../../../../components/shared/Disclaimer';
import { Button } from '../../../../../components/shared/Button';
import cn from 'classnames';

const Summary = ({ handleRefundRadio, handleSubmit, values, loadApprove }) => {
  const [isFirstChecked, setIsFirstChecked] = useState(false);
  const [isSecondChecked, setIsSecondChecked] = useState(false);

  return (
    <div className={styles.container}>
      {values.netVatDue < 0 && (
        <>
          <p className={styles.title}>Refund Information</p>
          <div className={styles.box}>
            <p className="mt-3">
              Your return form information indicate that you are in a credit
              position, your credit amount will be carried forward for next
              filling.
            </p>
            <div className={styles.radioContainer}>
              <p className="mt-3 mb-2">
                If you want to request refund for current period please click
                here:
              </p>
              <span className={styles.radioInner}>
                <input
                  onChange={handleRefundRadio}
                  name="refundRadio"
                  type="radio"
                  value={true}
                />
                <label>Yes</label>
              </span>
              <span className={styles.radioInner}>
                <input
                  onChange={handleRefundRadio}
                  name="refundRadio"
                  type="radio"
                  value={false}
                />
                <label>No</label>
              </span>
            </div>
          </div>
        </>
      )}
      <p className={styles.title}>Declaration</p>
      <div className={styles.box}>
        <Disclaimer
          setIsChecked={setIsFirstChecked}
          disclaimerText={
            'I certify that the information given in this return is, to the best of my knowledge, true, correct, and complete in every respect. I am the person who is required to file this return or I am authorized to sign on behalf of that person. I also understand that I will be charged heavy penalties for submitting incorrect information'
          }
        />
        <Disclaimer
          setIsChecked={setIsSecondChecked}
          disclaimerText={
            'I acknowledge that the above bank account information is correct and assume full responsibility if the information is wrong. If the refund request fails, I agree to transfer the refund amount to be a Carry Forward balance for the next period'
          }
        />
        {isSecondChecked && isFirstChecked && (
          <Button
            className={cn('mx-5 col-2 mt-5', styles.continueBtn)}
            onClick={handleSubmit}
            loading={loadApprove}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(Summary);
