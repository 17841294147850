import { COLORS } from './constant';

export const ROUTES = [
  {
    label: 'dashboard_and_statistics',
    link: '',
  },
  {
    label: 'breadcrumb_e_invoicing',
    link: 'e-invoicing',
  },
];

export const VALUES = ({ t, env, values }) => {
  return [
    {
      type: 'certifications',
      values: {
        data: {
          title: t('chart_certifications'),
          items: [
            {
              numbers: values?.certifications?.active,
              title: t('chart_active'),
              type: 'active',
            },
            {
              numbers: values?.certifications?.expired,
              title: t('chart_expired'),
              type: 'expired',
            },
            {
              numbers: values?.certifications?.inProcess,
              title: t('chart_in_process'),
              type: 'inProcess',
            },
            {
              numbers: values?.certifications?.inActive,
              title: t('chart_inactive'),
              type: 'inactive',
            },
          ],
        },
        colors: COLORS[env],
      },
    },
    {
      type: 'registrations',
      values: {
        data: {
          title: t('chart_registrations'),
          items: [
            {
              numbers: values?.registrations?.active,
              title: t('chart_active'),
              type: 'active',
            },
            {
              numbers: values?.registrations?.expired,
              title: t('chart_expired'),
              type: 'expired',
            },
            {
              numbers: values?.registrations?.inProcess,
              title: t('chart_in_process'),
              type: 'inProcess',
            },
            {
              numbers: values?.registrations?.inActive,
              title: t('chart_inactive'),
              type: 'inactive',
            },
          ],
        },
        colors: COLORS[env],
      },
    },
    {
      type: 'eInvoicing',
      values: {
        data: {
          title: t('chart_e_invoicing'),
          items: [
            {
              numbers: values?.eInvoicing?.submitted,
              title: t('chart_accepted'),
              type: 'accepted',
            },
            {
              numbers: values?.eInvoicing?.rejected,
              title: t('chart_rejected'),
              type: 'rejected',
            },
          ],
        },
        colors: COLORS[env]?.slice(0, 2),
      },
    },
  ];
};
