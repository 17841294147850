export const INPUTS = (isReduced) => [
  {
    fieldName: 'sales.standardRatedSales',
    title: 'Standard Rated Sales 15%',
    adjustmentName: 'newSales.standardAdj',
    taxAmountName: 'sales.standardRateTaxAmount',
    reasonName: 'newSales.standardReason',
  },
  {
    fieldName: 'sales.reducedRate',
    title: 'Standard Rated Sales 5%*',
    adjustmentName: 'newSales.reducedAdj',
    taxAmountName: 'sales.reducedRateTaxAmount',
    reasonName: 'newSales.reducedReason',
    hide: !isReduced,
  },
  {
    fieldName: 'sales.governmentSales',
    title: 'Sales against which the state bears the tax',
    adjustmentName: 'newSales.stateTaxableSalesAdj',
    reasonName: 'newSales.stateTaxableSalesReason',
  },
  {
    fieldName: 'sales.zeroRatedSales',
    title: 'Zero-rated domestic sales',
    adjustmentName: 'newSales.zeroRatedAdj',
    reasonName: 'newSales.zeroRatedReason',
    hideTaxField: true,
  },
  {
    fieldName: 'sales.exports',
    title: 'Exports',
    adjustmentName: 'newSales.exportsAdj',
    reasonName: 'newSales.exportsReason',
    hideTaxField: true,
  },
  {
    fieldName: 'sales.exempt',
    title: 'Exempt Sales',
    adjustmentName: 'newSales.exemptAdj',
    reasonName: 'newSales.exemptReason',
    hideTaxField: true,
  },
  {
    fieldName: 'sales.totalSales',
    title: 'Total Sales',
    adjustmentName: 'newSales.totalSalesAdj',
    taxAmountName: 'sales.totalSalesTaxAmount',
    disabled: true,
    bold: true,
  },
];

export const SALES_REASON_OPTIONS = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Timing difference',
    value: 'TIMING_DIFFERENCE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];
