import React from 'react';
import cn from 'classnames';

import styles from './FormControl.styles.scss';

const FormControl = ({
  className,
  hasError,
  required,
  label,
  labelProps,
  error,
  children,
}) => (
  <div className={cn(styles.control, className)}>
    {label && (
      <p
        {...labelProps}
        className={cn(
          styles.label,
          { [styles.labelRequired]: required },
          labelProps?.className,
        )}
      >
        {label}{' '}
      </p>
    )}
    {children}
    {hasError && error && (
      <div className={styles.error}>
        <span className={styles.errorText}>{error}</span>
      </div>
    )}
  </div>
);

export default React.memo(FormControl);
