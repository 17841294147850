import React from 'react';
import { MainLayout } from '../../../components/MainLayout';
import { PagesHeader } from '../../../components/shared/PagesHeader';
import { Stepper } from '../../../components/shared/Stepper';
import { BackButton } from '../../../components/shared/BackButton';
import styles from './TaxReturnDetails.styles.scss';
import { ROUTES, STEPS } from './TaxReturnDetails.config';
import { SectionHeader } from './components/SectionHeader';
import { SuccessModal } from './components/SuccessModal';
import { ViewReturnForm } from './components/ViewReturnForm';

const TaxReturnDetails = ({
  loading,
  activeStep,
  getStepComponent,
  visible,
  setVisible,
  successResponse,
  approved,
  reportData,
}) => {
  return (
    <MainLayout eInvoicing={true} loading={loading}>
      <PagesHeader isTaxReturn={true} withBreadcrumb={true} routes={ROUTES} />
      <BackButton />
      {!approved && (
        <>
          <Stepper
            stepClassName={'col-3'}
            steps={STEPS()}
            activeStep={activeStep}
            containerClassName={styles.stepperContainer}
          />
          <SectionHeader styles={styles} title={activeStep.label} />
        </>
      )}
      {!approved ? (
        <div className={styles.stepWrapper}>
          {getStepComponent(activeStep.value)}
          <SuccessModal
            successResponse={successResponse}
            visible={visible}
            setVisible={setVisible}
          />
        </div>
      ) : (
        <ViewReturnForm values={reportData} />
      )}
    </MainLayout>
  );
};

export default React.memo(TaxReturnDetails);
