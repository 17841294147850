import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({});

export const initialValues = {
  search: '',
  onboardingDateFrom: '',
  onboardingDateTo: '',
  certificateExpiryDateFrom: '',
  certificateExpiryDateTo: '',
  revocationDateFrom: '',
  revocationDateTo: '',
};

export default {
  initialValues,
  validationSchema,
};
