import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useCustomContext } from '../../context/customContext';
import { Loader } from '../shared/Loading';
import { TOKEN_KEY } from '../../constants/common';

const PrivateRoute = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { dispatch } = useCustomContext();
  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    if (isLoggedIn === false) {
      keycloak.login();
    } else if (isLoggedIn === true) {
      keycloak.loadUserInfo().then((resp) => {
        dispatch({ type: 'SET_USER', payload: resp });
        sessionStorage.setItem(TOKEN_KEY, keycloak.idToken);
      });
    }
  }, [isLoggedIn]);

  return isLoggedIn ? children : <Loader active={true} />;
};

export default React.memo(PrivateRoute);
