export const INVOICE_TYPES = {
  standard: { ar: 'فاتورة ضريبية', en: 'Tax Invoice' },
  simplified: { ar: 'فاتورة ضريبية مبسطة', en: 'Simplified Tax Invoice' },
};

export const INVOICE_TYPES_ENUM = ({ category, type, noteType }) => {
  let value = '';

  if (category === 'standard') {
    value += 'STANDARD_';
  } else {
    value += 'SIMPLIFIED_';
  }

  if (type === 'e_invoice') {
    value += 'TAX';
  } else {
    value += noteType + '_NOTE';
  }

  return value;
};

export const INVOICE_TYPE_LABELS = (lang) =>
  lang === 'ar'
    ? {
        STANDARD_TAX: 'فاتورة ضريبية',
        STANDARD_CREDIT_NOTE: 'إشعار دائن',
        STANDARD_DEBIT_NOTE: 'إشعار مدين',
        SIMPLIFIED_TAX: 'فاتورة ضريبية مبسطة',
        SIMPLIFIED_CREDIT_NOTE: 'إشعار مدين',
        SIMPLIFIED_DEBIT_NOTE: 'فاتورة ضريبية مبسطة',
      }
    : {
        STANDARD_TAX: 'Standard Invoice',
        STANDARD_CREDIT_NOTE: 'Standard Credit Note',
        STANDARD_DEBIT_NOTE: 'Standard Debit Note',
        SIMPLIFIED_TAX: 'Simplified Invoice',
        SIMPLIFIED_CREDIT_NOTE: 'Simplified Credit Note',
        SIMPLIFIED_DEBIT_NOTE: 'Simplified Debit Note',
      };

export const STANDARD_INVOICE_TYPE_TRANSACTION = '0100000';
export const SIMPLIFIED_INVOICE_TYPE_TRANSACTION = '0200000';

export const TAX_TYPES = () => {
  return {
    S: '9%',
    Z: '0%',
  };
};
