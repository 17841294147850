import React, { useState, useEffect } from 'react';
import ManageExistingSolutions from './ManageExistingSolutions';
import * as api from '../../services/apis';
import { useCustomContext } from '../../context/customContext';

const ManageExistingSolutionsContainer = () => {
  const [renderSolutions, setRenderSolutions] = useState(
    Array.from({ length: 4 }),
  );
  const [originalSolutions, setOriginalSolutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useCustomContext();

  const getSolutions = (taxNumber) => {
    api
      .getRegisteredSolutions(taxNumber)
      .then((resp) => {
        setOriginalSolutions(resp?.data?.pcsidResponseList);
        setRenderSolutions(resp?.data?.pcsidResponseList);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      getSolutions(user.tax_number);
    }
  }, [user]);

  return (
    <ManageExistingSolutions
      solutions={renderSolutions}
      originalSolutions={originalSolutions}
      setRenderSolutions={setRenderSolutions}
      loading={loading}
    />
  );
};

export default React.memo(ManageExistingSolutionsContainer);
