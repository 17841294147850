import React, { createContext, useReducer, useContext } from 'react';

const CustomContext = createContext();

function customReducer(state, action) {
  switch (action.type) {
    case 'SET_USER': {
      return { ...state, user: action.payload };
    }
    case 'SET_TOAST': {
      return { ...state, toast: action.payload };
    }
  }
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(customReducer, {
    user: null,
    toast: null,
  });
  const value = { state, dispatch };

  return (
    <CustomContext.Provider value={value}>{children}</CustomContext.Provider>
  );
};

const useCustomContext = () => {
  const context = useContext(CustomContext);

  if (context === undefined) {
    throw new Error('useCustomContext must be used within a Provider');
  }
  return {
    user: context.state.user,
    dispatch: context.dispatch,
    toast: context.state.toast,
  };
};

export { Provider, useCustomContext };
