import React from 'react';
import { OvalSpinner } from '../../../../../../components/shared/OvalSpinner';
import cn from 'classnames';

const SectionHeader = ({ invoiceCount, invoiceLimit, title, styles }) => {
  return (
    <div className={styles.sectionHeader}>
      <p className={styles.title}>{title}</p>
      <div className={cn(styles.invoiceCount, 'mb-5')}>
        Generated invoices:{' '}
        {invoiceLimit > 0 ? (
          invoiceCount + ' / ' + invoiceLimit
        ) : (
          <OvalSpinner width={15} height={15} />
        )}
      </div>
    </div>
  );
};

export default React.memo(SectionHeader);
