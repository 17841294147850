import React from 'react';

const Questions = ({
  styles,
  handleReducedRadio,
  handleGovernmentRadio,
  isReduced,
}) => {
  return (
    <div className={styles.questions}>
      <div className={styles.reducedVatQuestion}>
        <p className={styles.question}>
          Do you have sales or purchases subject to 5% VAT as per the
          transitional provisions or sales, purchases, imports and adjustments
          subject to 5% VAT that you would like to declare in the period?
        </p>
        <div className={styles.radioContainer}>
          <span className={styles.radioInner}>
            <input
              onChange={handleReducedRadio}
              name="reducedVatQuestion"
              type="radio"
              value={true}
            />
            <label>Yes</label>
          </span>
          <span className={styles.radioInner}>
            <input
              onChange={handleReducedRadio}
              name="reducedVatQuestion"
              type="radio"
              value={false}
            />
            <label>No</label>
          </span>
        </div>
      </div>
      <div className={styles.governmentQuestion}>
        <p className={styles.question}>
          Do you have government agencies supplies that are subject to VAT at
          basic rate according to the government tenders and procurement system?
        </p>
        <div className={styles.radioContainer}>
          <span className={styles.radioInner}>
            <input
              onChange={handleGovernmentRadio}
              name="governmentQuestion"
              type="radio"
              value={true}
            />
            <label>Yes</label>
          </span>
          <span className={styles.radioInner}>
            <input
              onChange={handleGovernmentRadio}
              name="governmentQuestion"
              type="radio"
              value={false}
            />
            <label>No</label>
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Questions);
