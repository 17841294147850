import React from 'react';
import Filters from './Filters';
import { initialValues } from './Filters.formConfig';
import { useCustomContext } from '../../../../context/customContext';

const FiltersContainer = ({ getRegisters }) => {
  const { user } = useCustomContext();

  const handleSubmitFilters = (data) => {
    if (user) {
      if (!data) {
        getRegisters({
          taxNumber: user.tax_number,
        });
      } else {
        getRegisters({
          taxNumber: user.tax_number,
          dateFrom: data.issueDateFrom,
          dateTo: data.issueDateTo,
          invoiceType: data.invoiceType,
          searchQuery: data.search,
        });
      }
    }
  };

  const handleReset = (resetForm) => {
    resetForm({ values: initialValues });
    handleSubmitFilters();
  };

  return (
    <Filters
      onResetFormClick={handleReset}
      onSubmitFilters={handleSubmitFilters}
    />
  );
};

export default React.memo(FiltersContainer);
