import React from 'react';
import ReturnsListTable from './ReturnsListTable';
import { Button } from '../../../../components/shared/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './ReturnsListTable.styles.scss';
import cn from 'classnames';

const ReturnsListTableContainer = ({ ...restProps }) => {
  const history = useNavigate();
  const { i18n } = useTranslation();

  const continueButton = (rowData) => (
    <Button
      className={cn(styles.continue, 'px-4')}
      onClick={() =>
        history(`/${i18n.language}/tax-return/return-details`, {
          state: rowData,
        })
      }
    >
      {rowData.approved ? 'View' : 'Continue'}
    </Button>
  );

  return <ReturnsListTable {...restProps} continueButton={continueButton} />;
};

export default React.memo(ReturnsListTableContainer);
