import React from 'react';
import styles from './Line.styles.scss';

const Line = ({ name }) => {
  return (
    <>
      <div className={styles[name]} />
    </>
  );
};

export default React.memo(Line);
