import React from 'react';
import { LineItemsTable } from './components/LineItemsTable';
import { TotalsTable } from './components/TotalsTable';

const InvoiceProductsSection = ({ items }) => {
  return (
    <>
      {items[0] && (
        <div>
          <LineItemsTable items={items} />
          <TotalsTable items={items} />
        </div>
      )}
    </>
  );
};

export default React.memo(InvoiceProductsSection);
