import React from 'react';

import { DatePickerInput } from '../shared/DatePickerInput';

const DatePickerInputField = ({ field, form, ...restProps }) => {
  const handleChange = (value) => {
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, value);
  };

  return (
    <DatePickerInput
      {...field}
      {...restProps}
      onChange={restProps.onChange || handleChange}
    />
  );
};

export default React.memo(DatePickerInputField);
