import React, { useState, useEffect } from 'react';
import { FormikField } from '../../../../../../../components/FormikField';
import {
  InputField,
  SelectField,
} from '../../../../../../../components/FormikFields';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import styles from './SelectWithInput.styles.scss';

const SelectWithInput = ({
  options,
  name,
  styles: containerStyles,
  disabled,
  className,
  allowedOptionsArray,
}) => {
  const { values, touched } = useFormikContext();

  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    const keys = name.split('.');
    const key1 = keys[0];
    const key2 = keys[1];
    const value = values?.[key1]?.[key2];
    const isTouched = touched?.[key1]?.[key2];
    if (allowedOptionsArray.indexOf(value) === -1 && isTouched) {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  }, [values, touched]);

  return (
    <div className={cn(className, styles.container)}>
      <FormikField
        name={name}
        component={SelectField}
        componentProps={{
          options: options,
          className: containerStyles.input,
          disabled,
        }}
        controlProps={{
          className: cn(showInput ? 'col-3' : 'col-12'),
        }}
      />
      {showInput && (
        <FormikField
          name={name}
          component={InputField}
          componentProps={{
            className: containerStyles.input,
          }}
          controlProps={{
            className: cn('col-9'),
          }}
        />
      )}
    </div>
  );
};

export default React.memo(SelectWithInput);
