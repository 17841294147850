export const PAGES = (SHOW_SME) => [
  {
    title: 'VAT Registration Amendments',
    iconName: 'sme',
    buttonName: 'Amendments',
    redirectUrl: '#',
    hide: !SHOW_SME,
  },
  {
    title: 'tax_return',
    iconName: 'taxReturn',
    buttonName: 'Tax Returns',
    redirectUrl: 'tax-return',
  },
  {
    title: 'VAT Refund Request',
    iconName: 'solReg',
    buttonName: 'Refund Request',
    redirectUrl: '#',
  },
  {
    title: 'VAT Deregistration',
    iconName: 'dashStat',
    buttonName: 'Deregistration',
    redirectUrl: '#',
  },
];
export const ROUTES = [
  {
    label: 'Indirect Taxes - Value Added Tax',
    link: '',
  },
];
