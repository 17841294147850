import React from 'react';
import { Loading } from 'react-loading-wrapper';
import 'react-loading-wrapper/dist/index.css';
import { THEMES } from '../../../constants/common';
import * as constants from '../../../constants/common';

const Loader = ({ active, children }) => {
  const KEYCLOAK_REALM =
    window._env_?.REACT_APP_KEYCLOAK_REALM || constants.KEYCLOAK_REALM;
  return (
    <Loading
      loading={active}
      color={THEMES[KEYCLOAK_REALM]?.primary || '#000000'}
      backgroundColor="transparent"
      fullPage
      size={100}
      speed="fast"
      loadingComponent="threeDots"
    >
      {active ? null : children}
    </Loading>
  );
};

Loading.defaultProps = {
  size: 20,
};

export default React.memo(Loader);
