import React from 'react';
import InvoicesTable from './InvoicesTable';
import styles from './InvoicesTable.styles.scss';
import { ViewButton } from './components/ViewButton';
import { DownloadButton } from './components/DownloadButton';
import { ContinueButton } from './components/ContinueButton';
import { NotSubmittedButton } from './components/NotSubmittedButton';

const InvoicesTableContainer = ({ invoices, ...restProps }) => {
  const getActions = (status, rowData) => {
    switch (status) {
      case 'SUBMITTED':
        return (
          <div className="d-flex">
            <ViewButton rowData={rowData} />
            <DownloadButton rowData={rowData} />
          </div>
        );
      case 'DRAFT':
        return (
          <div className="d-flex">
            <ContinueButton rowData={rowData} />
          </div>
        );
      case 'REJECTED':
        return (
          <div className="d-flex">
            <NotSubmittedButton rowData={rowData} />
            <ViewButton rowData={rowData} />
            <DownloadButton rowData={rowData} />
          </div>
        );
      default:
        return '-';
    }
  };

  const renderStatus = ({ status, t }) => {
    switch (status) {
      case 'SUBMITTED':
        return (
          <div className={styles.statusCell}>
            <span className="bg-success mx-1" />
            <p className="text-success">{t('invoice_list.cleared_reported')}</p>
          </div>
        );
      case 'DRAFT':
        return (
          <div className={styles.statusCell}>
            <span className="bg-warning mx-1" />
            <p className="text-warning">{t('invoice_list.draft')}</p>
          </div>
        );
      case 'REJECTED':
        return (
          <div className={styles.statusCell}>
            <span className="bg-danger mx-1" />
            <p className="text-danger">{t('invoice_list.not_submitted')}</p>
          </div>
        );
    }
  };

  return (
    <InvoicesTable
      getActions={getActions}
      invoices={invoices}
      renderStatus={renderStatus}
      {...restProps}
    />
  );
};

export default React.memo(InvoicesTableContainer);
