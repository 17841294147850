import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { Provider } from './context/customContext';

import { EInvoicingPortal } from './pages/EInvoicingPortal';
import { SolutionRegistration } from './pages/SolutionRegistration';
import { InitiateNewRegistration } from './pages/InitiateNewRegistration';
import { ManageExistingSolutions } from './pages/ManageExistingSolutions';
import { Dashboard } from './pages/Dashboard';
import { CreateInvoice } from './pages/SmePortal/pages/CreateInvoice';
import { InvoiceList } from './pages/SmePortal/pages/InvoiceList';
import { ViewInvoice } from './pages/SmePortal/pages/ViewInvoice';

import { TaxReturn } from './pages/TaxReturn';
import { TaxReturnDetails } from './pages/TaxReturn/TaxReturnDetails';
import { ReturnHome } from './pages/TaxReturn/pages/ReturnHome';
import { PurchasesRegister } from './pages/TaxReturn/pages/PurchasesRegister';
import { SalesRegister } from './pages/TaxReturn/pages/SalesRegister';

import { SmePortal } from './pages/SmePortal';
import { InnerDashboard as SmeInnerDashboard } from './pages/SmePortal/pages/InnerDashboard';

import { POCHome } from './pages/POCHome';

import { useTranslation } from 'react-i18next';
import keycloak from './config/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import {
  initiateServiceInstance,
  initiateSmeInstance,
  initiateCertificationInstance,
  initiatePrintInstance,
  initiateTaxReturnInstance,
} from './config/axios';
import * as constants from './constants/common';
import { changeTheme } from './helpers/changeTheme';
import { isTrue } from './helpers/common';

function App() {
  const { i18n } = useTranslation();
  const baseRoute = `/${i18n.language}`;

  const KEYCLOAK_CLIENT_ID =
    window._env_?.REACT_APP_KEYCLOAK_CLIENT_ID || constants.KEYCLOAK_CLIENT_ID;
  const KEYCLOAK_URL =
    window._env_?.REACT_APP_KEYCLOAK_URL || constants.KEYCLOAK_URL;
  const KEYCLOAK_REALM =
    window._env_?.REACT_APP_KEYCLOAK_REALM || constants.KEYCLOAK_REALM;
  const API_URL = window._env_?.REACT_APP_API_URL || constants.API_URL;

  const SME_URL = window._env_?.REACT_APP_SME_URL || constants.SME_URL;

  const ONBOARDING_GRAVITEE_KEY =
    window._env_?.REACT_APP_ONBOARDING_GRAVITEE_KEY ||
    constants.ONBOARDING_GRAVITEE_KEY;

  const SME_GRAVITEE_KEY =
    window._env_?.REACT_APP_SME_GRAVITEE_KEY || constants.SME_GRAVITEE_KEY;

  const CERTIFICATION_GRAVITEE_KEY =
    window._env_?.REACT_APP_CERTIFICATION_GRAVITEE_KEY ||
    constants.CERTIFICATION_GRAVITEE_KEY;

  const PRINT_URL = window._env_?.REACT_APP_PRINT_URL || constants.PRINT_URL;

  const TAX_RETURN_URL =
    window._env_?.REACT_APP_TAX_RETURN_URL || constants.TAX_RETURN_URL;

  const SHOW_SME = isTrue(
    window._env_.REACT_APP_SHOW_SME || constants.SHOW_SME,
  );

  const TAX_COUNTRY =
    window._env_?.REACT_APP_TAX_COUNTRY || constants.TAX_COUNTRY;

  const IS_POC = isTrue(window._env_.REACT_APP_IS_POC || constants.IS_POC);

  initiateServiceInstance({ API_URL, ONBOARDING_GRAVITEE_KEY });
  initiateSmeInstance({ SME_URL, SME_GRAVITEE_KEY });
  initiateCertificationInstance({ API_URL, CERTIFICATION_GRAVITEE_KEY });
  initiatePrintInstance({ PRINT_URL });
  initiateTaxReturnInstance({ TAX_RETURN_URL });

  changeTheme(constants.THEMES[KEYCLOAK_REALM] || constants.THEMES.eiv);

  useEffect(() => {
    document.title = KEYCLOAK_REALM;
  }, []);

  return (
    <BrowserRouter>
      <Provider>
        <ReactKeycloakProvider
          authClient={keycloak({
            KEYCLOAK_CLIENT_ID,
            KEYCLOAK_URL,
            KEYCLOAK_REALM,
          })}
        >
          <Routes>
            <Route path={`/${baseRoute}`}>
              <Route
                path=""
                element={
                  IS_POC ? (
                    <POCHome
                      KEYCLOAK_REALM={KEYCLOAK_REALM}
                      SHOW_SME={SHOW_SME}
                    />
                  ) : (
                    <EInvoicingPortal
                      KEYCLOAK_REALM={KEYCLOAK_REALM}
                      SHOW_SME={SHOW_SME}
                    />
                  )
                }
              />
              <Route path="solution-registration">
                <Route path="" element={<SolutionRegistration />} />
                <Route
                  path="initiate-new-registration"
                  element={<InitiateNewRegistration />}
                />
                <Route
                  path="manage-existing-solution"
                  element={<ManageExistingSolutions />}
                />
              </Route>
              <Route path="dashboard" element={<Dashboard />} />
              {SHOW_SME && (
                <Route path="sme">
                  <Route path="" element={<SmePortal />} />
                  <Route path="standard">
                    <Route
                      path=""
                      element={<SmeInnerDashboard type="standard" />}
                    />
                    <Route
                      path="invoice"
                      element={
                        <CreateInvoice
                          taxCountry={TAX_COUNTRY}
                          type="e_invoice"
                          category="standard"
                        />
                      }
                    />
                    <Route
                      path="note"
                      element={
                        <CreateInvoice
                          taxCountry={TAX_COUNTRY}
                          type="creditDebit"
                          category="standard"
                        />
                      }
                    />
                  </Route>
                  <Route path="simplified">
                    <Route
                      path=""
                      element={<SmeInnerDashboard type="simplified" />}
                    />
                    <Route
                      path="invoice"
                      element={
                        <CreateInvoice
                          taxCountry={TAX_COUNTRY}
                          type="e_invoice"
                          category="simplified"
                        />
                      }
                    />
                    <Route
                      path="note"
                      element={
                        <CreateInvoice
                          type="creditDebit"
                          category="simplified"
                          taxCountry={TAX_COUNTRY}
                        />
                      }
                    />
                  </Route>
                  {/* <Route
                    path="standard-note"
                    element={<CreateInvoice type="standard-note" />}
                  />
                   */}
                  <Route path="view-list">
                    <Route path="" element={<InvoiceList />} />
                    <Route
                      path="view-invoice/:id/:type"
                      element={<ViewInvoice />}
                    />
                  </Route>
                </Route>
              )}
              <Route path="tax-return">
                <Route path="" element={<ReturnHome />} />
                <Route path="display-submit-returns" element={<TaxReturn />} />
                <Route path="return-details" element={<TaxReturnDetails />} />
                <Route
                  path="purchase-register"
                  element={<PurchasesRegister />}
                />
                <Route path="sales-register" element={<SalesRegister />} />
              </Route>
            </Route>

            <Route
              path="/*"
              element={<Navigate to={`${baseRoute}`} replace={true} />}
            />
          </Routes>
        </ReactKeycloakProvider>
        <ToastContainer hideProgressBar newestOnTop={false} closeOnClick />
      </Provider>
    </BrowserRouter>
  );
}

export default React.memo(App);
