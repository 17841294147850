import React from 'react';
import styles from './Stepper.styles.scss';
import { Step } from './components/Step';
import cn from 'classnames';

const Stepper = ({ steps, activeStep, stepClassName, containerClassName }) => {
  return (
    <div className={cn(styles.container, containerClassName)}>
      {steps.map((_step, i) => (
        <Step
          activeIndex={steps.findIndex((obj) => obj.value === activeStep.value)}
          index={i}
          length={steps.length}
          key={i}
          className="row"
          stepClassName={stepClassName}
        />
      ))}
    </div>
  );
};

export default React.memo(Stepper);
