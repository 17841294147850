import React, { useState, useEffect } from 'react';
import styles from './Footer.styles.scss';
import { FooterSection } from './components/FooterSection';
import { FOOTER_LINKS } from './Footer.config';
import cn from 'classnames';
import * as constants from '../../constants/common';

const Footer = () => {
  const [footerData, setFooterData] = useState(FOOTER_LINKS);

  const environment =
    window._env_.REACT_APP_KEYCLOAK_REALM || constants.KEYCLOAK_REALM;

  useEffect(() => {
    if (environment !== 'OTA') {
      setFooterData([
        {
          title: { en: 'Latest News', ar: 'آخر المستجدات' },
          links: Array(5).fill({
            label: {
              en: 'Example News',
              ar: 'Example News',
            },
            url: '',
          }),
        },
        {
          title: { en: 'Latest Events', ar: 'آخر الأنشطة' },
          links: Array(2).fill({
            label: {
              en: 'Example Event',
              ar: 'Example Event',
            },
            url: '',
          }),
        },
        {
          title: { en: 'Tax Portal', ar: 'منصة الضريبة' },
          links: Array(5).fill({
            label: { en: 'Example Link', ar: 'Example Link' },
            url: '',
          }),
        },
        {
          title: { en: 'Contact Us', ar: 'تواصل معنا' },
          links: [
            {
              label: { en: 'Contact us', ar: 'تواصل معنا' },
              icon: 'contactUsLogo',
              url: 'tel:+000000000',
            },
            {
              label: { en: 'info@example.com', ar: 'info@example.com' },
              icon: 'emailLogo',
              url: 'mailto:info@example.com',
            },
            {
              label: {
                en: 'Example, Example, avenue',
                ar: 'Example, Example, avenue',
              },
              icon: 'addressLogo',
              url: '',
            },
          ],
          type: 'social',
        },
      ]);
    }
  }, []);

  return (
    <div className={cn(styles.footer, 'p-5')}>
      <div className={cn(styles.inner, 'row')}>
        {footerData.map((item, i) => (
          <FooterSection key={i} data={item} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(Footer);
