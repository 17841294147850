import React from 'react';
import styles from './TotalsTable.styles.scss';
import cn from 'classnames';
import {
  calculateTotalExcludingTax,
  calculateTotalIncludingTax,
  calculateTotalDiscountAmount,
  calculateTotalVatAmount,
} from '../../../../../../../../helpers/invoiceHelpers';

const TotalsTable = ({ items }) => {
  return (
    <div className={cn('mt-5', styles.container)}>
      <div className={styles.inner}>
        <div className={cn(styles.headers, styles.firstHeader)}>
          <p>{'Invoice Total Amount (excluding VAT)'}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalExcludingTax(items)).toFixed(3)}
        </div>
      </div>
      <div className={styles.inner}>
        <div className={styles.headers}>
          <p>{'Discount or rebate total amount'}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalDiscountAmount(items)).toFixed(3)}
        </div>
      </div>
      <div className={styles.inner}>
        <div className={styles.headers}>
          <p>{'VAT total amount'}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalVatAmount(items)).toFixed(3)}
        </div>
      </div>
      <div className={styles.inner}>
        <div className={styles.headers}>
          <p>{'Invoice Gross Total (inclusive of VAT)'}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalIncludingTax(items)).toFixed(3)}
        </div>
      </div>
      <div className={styles.inner}>
        <div className={cn(styles.headers, styles.lastHeader)}>
          <p>{'Invoice Total Payable Amount'}</p>
        </div>
        <div className={styles.data}>
          {Number(calculateTotalIncludingTax(items)).toFixed(3)}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TotalsTable);
