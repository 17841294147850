export const REASONS_VALUE = {
  PROPORTIONAL_DEDUCTION: 'Proportional Deduction',
  TIMING_DIFFERENCE: 'Timing difference',
  NON_BUSINESS: 'Non-business expense',
  OTHER: 'Other',
};

const getNewOrOld = (val1, val2) => {
  const num1 = +val1;
  const num2 = +val2;
  return num1 ? val1 : num2 ? val2 : 0.0;
};

export const VIEW_STRUCTURE = (values) => ({
  sales: {
    headers: [
      'VAT on sales',
      'Amount (SAR)',
      'Adjustment (SAR)',
      'Reasons',
      'VAT Amount (SAR)',
    ],
    inputs: [
      {
        label: 'Standard Rated Sales 15%',
        value: getNewOrOld(
          values?.newSales?.standardAmount,
          values?.sales?.standardRatedSales,
        ),
        adjustment: values?.newSales?.standardAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newSales?.standardReason] ||
          values?.newSales?.standardReason ||
          '',
        tax: values?.sales?.standardRateTaxAmount || 0.0,
        newTax: values?.newSales?.standardVat || 0.0,
      },
      {
        label: 'Standard Rated Sales 5%*',
        value: getNewOrOld(
          values?.newSales?.reducedAmount,
          values?.sales?.reducedRate,
        ),
        adjustment: values?.newSales?.reducedAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newSales?.reducedReason] ||
          values?.newSales?.reducedReason ||
          '',
        tax: values?.sales?.reducedRateTaxAmount || 0.0,
        newTax: values?.newSales?.reducedVat || 0.0,
        hide: !values?.reduced,
      },
      {
        label: 'Sales against which the state bears the tax',
        value: getNewOrOld(
          values?.newSales?.stateTaxableSales,
          values?.sales?.governmentSales,
        ),
        adjustment: values?.newSales?.stateTaxableSalesAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newSales?.stateTaxableSalesReason] ||
          values?.newSales?.stateTaxableSalesReason ||
          '',
        tax: '',
      },
      {
        label: 'Zero-rated domestic sales',
        value: getNewOrOld(
          values?.newSales?.zeroRated,
          values?.sales?.zeroRatedSales,
        ),
        adjustment: values?.newSales?.zeroRatedAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newSales?.zeroRatedReason] ||
          values?.newSales?.zeroRatedReason ||
          '',
        tax: '',
        hideTax: true,
      },
      {
        label: 'Exports',
        value: getNewOrOld(values?.newSales?.exports, values?.sales?.exports),
        adjustment: values?.newSales?.exportsAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newSales?.exportsReason] ||
          values?.newSales?.exportsReason ||
          '',
        tax: '',
        hideTax: true,
      },
      {
        label: 'Exempt Sales',
        value: getNewOrOld(values?.newSales?.exempt, values?.sales?.exempt),
        adjustment: values?.newSales?.exemptAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newSales?.exemptReason] ||
          values?.newSales?.exemptReason ||
          '',
        tax: '',
        hideTax: true,
      },
      {
        label: 'Total Sales',
        value: getNewOrOld(
          values?.newSales?.totalSales,
          values?.sales?.totalSales,
        ),
        adjustment: values?.newSales?.totalSalesAdj || 0.0,
        reason: '',
        tax:
          values?.newSales?.totalSalesVat ||
          values?.sales?.totalSalesTaxAmount ||
          0.0,
      },
    ],
  },
  purchases: {
    title: '',
    headers: [
      'VAT on purchase',
      'Amount (SAR)',
      'Adjustment (SAR)',
      'Reasons',
      'VAT Amount (SAR)',
    ],
    inputs: [
      {
        label: 'Standard Rated domestic purchases 15%',
        value: getNewOrOld(
          values?.newPurchases?.standardRatedPurchase,
          values?.purchases?.standardRatedPurchase,
        ),
        adjustment: values?.newPurchases?.standardRatedAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newPurchases?.standardRateReason] ||
          values?.newPurchases?.standardRateReason ||
          '',
        tax: values?.purchases?.standardRateTaxAmount || 0.0,
        newTax: values?.newPurchases?.standardRateTaxAmount || 0.0,
      },
      {
        label: 'Reduced Rated domestic purchases 5%',
        value: getNewOrOld(
          values?.newPurchases?.reducedRate,
          values?.purchases?.reducedRate,
        ),
        adjustment: values?.newPurchases?.reducedRateAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newPurchases?.reducedRateReason] ||
          values?.newPurchases?.reducedRateReason ||
          '',
        tax: values?.purchases?.reducedRateTaxAmount || 0.0,
        newTax: values?.newPurchases?.reducedRateTaxAmount || 0.0,
        hide: !values.reduced,
      },
      {
        label: 'Imports subject to VAT paid at customs (15%)',
        value: getNewOrOld(
          values?.newPurchases?.importsSubjectToVat,
          values?.purchases?.importsSubjectToVat,
        ),
        adjustment: values?.newPurchases?.importsSubjectToVatAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newPurchases?.importsReason] ||
          values?.newPurchases?.importsReason ||
          '',
        tax: values?.purchases?.importsSubjectToVatTaxAmount || 0.0,
        newTax: values?.newPurchases?.importsSubjectToVatTaxAmount || 0.0,
      },
      {
        label: 'Imports subject to VAT paid at customs (5%)',
        value: getNewOrOld(
          values?.newPurchases?.reducedImportsSubjectToVat,
          values?.purchases?.reducedImportsSubjectToVat,
        ),
        adjustment: values?.newPurchases?.reducedImportsSubjectToVatAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newPurchases?.reducedImportsReason] ||
          values?.newPurchases?.reducedImportsReason ||
          '',
        tax: values?.purchases?.reducedImportsSubjectToVatTaxAmount || 0.0,
        newTax:
          values?.newPurchases?.reducedImportsSubjectToVatTaxAmount || 0.0,
        hide: !values.reduced,
      },
      {
        label:
          'Imports subject to VAT accounted for through reverse charge mechanisms (15%)',
        value: getNewOrOld(
          values?.newPurchases?.reverseImports,
          values?.purchases?.importsReverseCharge,
        ),
        adjustment: values?.newPurchases?.reverseImportsAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newPurchases?.reverseImportsReason] ||
          values?.newPurchases?.reverseImportsReason ||
          '',
        tax: values?.purchases?.importsReverseChargeTaxAmount || 0.0,
        newTax: values?.newPurchases?.reverseImportsTaxAmount || 0.0,
      },
      {
        label:
          'Imports subject to VAT accounted for through reverse charge mechanisms (5%)',
        value: getNewOrOld(
          values?.newPurchases?.reducedReverseImports,
          values?.purchases?.reducedImportsReverseCharge,
        ),
        adjustment: values?.newPurchases?.reducedReverseImportsAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newPurchases?.reducedReverseImportsReason] ||
          values?.newPurchases?.reducedReverseImportsReason ||
          '',
        tax: values?.purchases?.reducedImportsReverseChargeTaxAmount || 0.0,
        newTax: values?.newPurchases?.reducedReverseImportsTaxAmount || 0.0,
        hide: !values.reduced,
      },
      {
        label: 'Zero-rated Purchases',
        value: getNewOrOld(
          values?.newPurchases?.zeroRated,
          values?.purchases?.zeroRatedSales,
        ),
        adjustment: values?.newPurchases?.zeroRatedAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newPurchases?.zeroReason] ||
          values?.newPurchases?.zeroReason ||
          '',
        tax: '',
        hideTax: true,
      },
      {
        label: 'Exempt Purchases',
        value: getNewOrOld(
          values?.newPurchases?.exempt,
          values?.purchases?.exempt,
        ),
        adjustment: values?.newPurchases?.exemptAdj || 0.0,
        reason:
          REASONS_VALUE[values?.newPurchases?.exemptReason] ||
          values?.newPurchases?.exemptReason ||
          '',
        tax: '',
        hideTax: true,
      },
      {
        label: 'Total Purchases',
        value: getNewOrOld(
          values?.newPurchases?.totalPurchases,
          values?.purchases?.totalPurchases,
        ),
        adjustment: values?.newPurchases?.totalPurchasesAdj || 0.0,
        reason: '',
        tax: values?.purchases?.totalPurchasesTaxAmount || 0.0,
        newTax: values?.newPurchases?.totalPurchasesTaxAmount || 0.0,
      },
      {
        label: 'Total VAT due for current period',
        value: '',
        adjustment: '',
        reason: '',
        tax: values?.currentTotalVatDue || 0.0,
        hideValue: true,
        hideAdj: true,
        hideReason: true,
      },
      {
        label: 'Corrections from previous period (between SAR 5,000+/-)',
        value: values?.correctionFromPreviousValue || 0.0,
        adjustment: values?.correctionFromPreviousAdj || 0.0,
        reason: '',
        tax: values?.correctionFromPrevious || 0.0,
        hideValue: true,
        hideAdj: true,
        hideReason: true,
      },
      {
        label: 'VAT credit carried forward from previous period(s)',
        value: values?.vatCreditCarriedForward || 0.0,
        adjustment: '',
        reason: '',
        tax: values?.purchases?.correctionFromPrevious || 0.0,
        hideValue: true,
        hideAdj: true,
        hideReason: true,
      },
      {
        label: 'Net VAT Due (or claim)',
        value: '',
        adjustment: '',
        reason: '',
        tax: values?.netVatDue || 0.0,
        hideValue: true,
        hideAdj: true,
        hideReason: true,
      },
    ],
  },
});
