import React from 'react';
import { MainLayout } from '../../../../components/MainLayout';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { InvoicesTable } from './components/InvoicesTable';
import styles from './InvoiceList.styles.scss';
import { ROUTES } from './InvoiceList.config';
import cn from 'classnames';
import { InvoicesListHeader } from './components/InvoicesListHeader';
import { Filters } from './components/Filters';

const InvoiceList = ({
  statistics,
  invoices,
  originalInvoices,
  setRenderInvoices,
  loading,
}) => {
  return (
    <MainLayout className={cn(styles.container)}>
      <div className="container">
        <Breadcrumbs routeNames={ROUTES} />
        <InvoicesListHeader statistics={statistics} />
        <Filters
          originalInvoices={originalInvoices}
          setRenderInvoices={setRenderInvoices}
        />
        <InvoicesTable invoices={invoices} loading={loading} />
      </div>
    </MainLayout>
  );
};

export default React.memo(InvoiceList);
