import React from 'react';
import OTPs from './OTPs';
import { showToast } from '../../../../helpers/showToast';
import { useTranslation } from 'react-i18next';

const OTPsContainer = ({ code }) => {
  const { t } = useTranslation();

  const copyText = (otp) => {
    navigator.clipboard.writeText(otp.join(''));
    showToast({
      data: {
        variant: 'info',
        text: t('copied'),
      },
    });
  };

  return <OTPs code={code} copyText={copyText} />;
};

export default React.memo(OTPsContainer);
