import React from 'react';
import styles from './Disclaimer.styles.scss';
import cn from 'classnames';

const Disclaimer = ({ setIsChecked, disclaimerText, inputClassName }) => {
  return (
    <div className={styles.declarationContainer}>
      <input
        className={cn(
          inputClassName ? inputClassName : 'mx-3',
          styles.checkbox,
        )}
        type="checkbox"
        onChange={(e) => setIsChecked(e?.target?.checked)}
      />
      <p>{disclaimerText}</p>
    </div>
  );
};

export default React.memo(Disclaimer);
