import { INVOICE_TYPE_LABELS } from '../../../../config/invoice/constants';

import {
  ADDITIONAL_SELLER_ID_TYPES,
  ADDITIONAL_BUYER_ID_TYPES,
  CITIES,
  PAYMENT_METHOD,
  NOTE_ISSUANCE_REASONS,
} from '../../../../config/invoice/options';
import { getQrCodeImage } from '../../../../helpers/invoiceHelpers';

export const ROUTES = (type) => {
  return [
    {
      label: 'view_invoice',
      link: '',
    },
    {
      label: 'sme',
      link: 'sme',
    },
    {
      label: 'breadcrumb_e_invoicing',
      link: 'e-invoicing',
    },
  ];
};

export const getTitles = (type) => {
  switch (type) {
    case 'STANDARD_TAX':
      return {
        en: 'Standard E-Invoice',
        ar: 'فاتورة الكترونية',
      };
    case 'STANDARD_CREDIT_NOTE':
      return {
        en: 'Standard Credit/Debit Note',
        ar: 'إشعار إلكتروني دائن أو مدين',
      };
    case 'STANDARD_DEBIT_NOTE':
      return {
        en: 'Standard Credit/Debit Note',
        ar: 'إشعار إلكتروني دائن أو مدين',
      };
    case 'SIMPLIFIED_TAX':
      return {
        en: 'Simplified E-Invoice',
        ar: 'فاتورة الكترونية مبسطة',
      };
    case 'SIMPLIFIED_CREDIT_NOTE':
      return {
        en: 'Simplified Credit/Debit Note',
        ar: 'إشعار إلكتروني مبسط دائن أو مدين',
      };
    case 'SIMPLIFIED_DEBIT_NOTE':
      return {
        en: 'Simplified Credit/Debit Note',
        ar: 'إشعار إلكتروني مبسط دائن أو مدين',
      };
  }
};

export const viewObject = (obj) => {
  const blocks = [
    {
      key: 'invoiceInfoBlock',
      sections: [
        {
          key: 'invoiceInfo',
          title: '',
          inputs: [
            {
              label: 'Invoice Reference Number (IRN)',
              value: obj.invoiceNumber,
            },
            {
              label: 'Issue Date',
              value: obj.issueDate,
            },
            {
              label: 'Invoice Type',
              value:
                (obj.invoiceTypeCode &&
                  INVOICE_TYPE_LABELS('en')[obj.invoiceTypeCode]) ||
                '',
            },
            {
              label: 'Invoice Type Transaction',
              value: obj.invoiceTypeTransaction,
            },
            {
              label: 'Supply Start Date',
              value: obj.supplyStartDate,
            },
            {
              label: 'Supply End Date',
              value: obj.supplyEndDate,
            },
            {
              label: 'Purchase Order Number',
              value: obj.purchaseOrderNumber,
            },
            {
              label: 'Contract Number',
              value: obj.contactNumber,
            },
          ],
        },
      ],
      image: getQrCodeImage(obj.qrCode),
    },
    {
      key: 'sellerBlock',
      sections: [
        {
          key: 'identification',
          title: 'Seller Identification',
          inputs: [
            {
              label: 'Seller Name',
              value: obj.sellerDTO.sellerEnglishName,
            },
            {
              label: 'VAT Registration Number',
              value: obj.sellerDTO.vatNumber,
            },
            {
              label: 'Group VAT registration Number',
              value: obj.sellerDTO.groupVatNumber,
            },
            {
              label: 'Additional Seller ID Type',
              value:
                (obj?.sellerDTO?.additionalSellerIdType &&
                  ADDITIONAL_SELLER_ID_TYPES('en')?.find(
                    (ele) => ele.value === obj.sellerDTO.additionalSellerIdType,
                  )?.label) ||
                '',
            },
            {
              label: 'Additional Seller ID Number',
              value: obj.sellerDTO.additionalSellerId,
            },
          ],
        },
        {
          key: 'address',
          title: 'Address',
          inputs: [
            {
              label: 'Street Name',
              value: obj.sellerDTO.sellerStreetEn,
            },
            {
              label: 'Additional Street Name',
              value: obj.sellerDTO.additionalSellerStreetEn,
            },
            {
              label: 'Building Number',
              value: obj.sellerDTO.sellerBuildingNumber,
            },
            {
              label: 'Additional Number',
              value: obj.sellerDTO.additionalSellerBuildingNumber,
            },
            {
              label: 'City',
              value:
                (obj?.sellerDTO?.sellerCity &&
                  CITIES('en').find(
                    (ele) => ele.value === obj.sellerDTO.sellerCity,
                  )?.label) ||
                '',
            },
            {
              label: 'Postal Code',
              value: obj.sellerDTO.sellerPostalCode,
            },
            {
              label: 'Province/State',
              value: obj.sellerDTO.sellerStateEn,
            },
            {
              label: 'Neighborhood',
              value: obj.sellerDTO.sellerNeighborhoodEn,
            },
            {
              label: 'Country Code',
              value: obj.sellerDTO.sellerCountryCode,
            },
          ],
        },
      ],
      image: null,
    },
    {
      key: 'buyerBlock',
      sections: [
        {
          key: 'identification',
          title: 'Buyer Identification',
          inputs: [
            {
              label: 'Buyer Name',
              value: obj.buyerDTO.buyerEnglishName,
            },
            {
              label: 'VAT Registration Number',
              value: obj.buyerDTO.buyerVatNumber,
            },
            {
              label: 'Group VAT registration Number',
              value: obj.buyerDTO.buyerGroupVatNumber,
            },
            {
              label: 'Additional Buyer ID Type',
              value:
                (obj.buyerDTO.additionalBuyerIdType &&
                  ADDITIONAL_BUYER_ID_TYPES('en')?.find(
                    (ele) => ele.value === obj.buyerDTO.additionalBuyerIdType,
                  )?.label) ||
                '',
            },
            {
              label: 'Additional Buyer ID Number',
              value: obj.buyerDTO.additionalBuyerId,
            },
          ],
        },
        {
          key: 'address',
          title: 'Address',
          inputs: [
            {
              label: 'Street Name',
              value: obj.buyerDTO.buyerStreetEn,
            },
            {
              label: 'Additional Street Name',
              value: obj.buyerDTO.additionalBuyerStreetEn,
            },
            {
              label: 'Building Number',
              value: obj.buyerDTO.buyerBuildingNumber,
            },
            {
              label: 'Additional Number',
              value: obj.buyerDTO.additionalBuyerBuildingNumber,
            },
            {
              label: 'City',
              value:
                (obj.buyerDTO.buyerCity &&
                  CITIES('en').find(
                    (ele) => ele.value === obj.buyerDTO.buyerCity,
                  )?.label) ||
                '',
            },
            {
              label: 'Postal Code',
              value: obj.buyerDTO.buyerPostalCode,
            },
            {
              label: 'Province/State',
              value: obj.buyerDTO.buyerStateEn,
            },
            {
              label: 'Neighborhood',
              value: obj.buyerDTO.buyerNeighborhoodEn,
            },
            {
              label: 'Country Code',
              value: obj.buyerDTO.buyerCountryCode,
            },
          ],
        },
      ],
      image: null,
    },
    {
      key: 'lineItemsBlock',
      title: 'LineItems',
      items: [...obj.invoiceItems],
    },
    {
      key: 'paymentTermsBlock',
      sections: [
        {
          key: 'terms',
          title: 'Payment Terms',
          inputs: [
            {
              label: 'Payment Method',
              value:
                (obj.paymentTermsDTO?.paymentMethodCode &&
                  PAYMENT_METHOD('en')?.find(
                    (method) =>
                      method.value === obj.paymentTermsDTO?.paymentMethodCode,
                  )?.label) ||
                '',
            },
            {
              label: 'Bank Name',
              value: obj.paymentTermsDTO?.bankNameEn,
              hide: obj.paymentTermsDTO?.paymentMethodCode !== 'BANK_TRANSFER',
            },
            {
              label: 'Account Number',
              value: obj.paymentTermsDTO?.accountNumber,
              hide: obj.paymentTermsDTO?.paymentMethodCode !== 'BANK_TRANSFER',
            },
            {
              label: 'Payment Terms',
              value: obj.paymentTermsDTO?.paymentTermsEn,
            },
          ],
          hide: !obj.paymentTermsDTO,
        },
      ],
      image: null,
    },
    {
      key: 'referenceBlock',
      sections: [
        {
          key: 'reference',
          title: 'Reference',
          inputs: [
            {
              label: 'Reference To Original Invoice',
              value: obj.originalInvoiceReference,
            },
            {
              label: 'Gross Amount of The Referenced Invoice',
              value: '',
            },
            {
              label:
                'Reason for Credit/Debit Note Issuance Per VAT Regulations',
              value:
                (obj.reasonOfNote &&
                  NOTE_ISSUANCE_REASONS('en')?.find(
                    (reason) => reason.value === obj.reasonOfNote,
                  )?.label) ||
                '',
            },
          ],
        },
      ],
      image: null,
    },
    {
      key: 'notesBlock',
      sections: [
        {
          key: 'notes',
          title: 'Notes',
          inputs: [
            {
              label: 'Special Tax Treatment',
              value: obj.specialTaxTreatmentEn,
            },
            {
              label: 'Notes',
              value: obj.enNotes,
            },
          ],
        },
      ],
      image: null,
    },
  ];

  if (obj.invoiceTypeCode.indexOf('NOTE') === -1) {
    blocks.splice(5, 1);
  }

  return blocks;
};
