import React from 'react';
import { VIEW_STRUCTURE } from './ViewReturnForm.config';
import styles from './ViewReturnForm.styles.scss';
import { randomKey } from '../../../../../helpers/common';
import cn from 'classnames';
import { TaxpayerDetails } from '../TaxpayerDetails';

const ViewReturnForm = ({ values }) => {
  const isChanged = (value1, value2) => {
    const number1 = +value1 || 0.0;
    const number2 = +value2 || 0.0;

    return number1 !== number2;
  };

  return (
    <div className={styles.container}>
      <TaxpayerDetails values={values} isApproved={true} />
      <div className={styles.purchaseBox}>
        <div className={cn(styles.headers, 'row')}>
          {VIEW_STRUCTURE(values).sales.headers.map((header) => (
            <p className="col-2" key={randomKey()}>
              {header}
            </p>
          ))}
        </div>
        <div>
          {VIEW_STRUCTURE(values).sales.inputs.map((input, i) =>
            !input.hide ? (
              <div key={i} className={cn('row', styles.salesInputs)}>
                <p className="col-2">{input.label}</p>
                <input className="col-2" value={input.value} disabled />
                <input className="col-2" value={input.adjustment} disabled />
                <input className="col-2" value={input.reason} disabled />
                <input
                  value={input.newTax || input.tax}
                  disabled
                  className={cn(
                    'col-2',
                    isChanged(input.newTax, input.tax)
                      ? styles.adjustedVat
                      : '',
                    input.hideTax ? styles.placeholderInput : '',
                  )}
                />
              </div>
            ) : (
              <React.Fragment key={randomKey()} />
            ),
          )}
        </div>
      </div>
      <div className={styles.purchaseBox}>
        <div className={styles.purchasesFormContainer}>
          <div className={cn(styles.purchaseHeaders, 'row')}>
            {VIEW_STRUCTURE(values).purchases.headers.map((header) => (
              <p key={header}>{header}</p>
            ))}
          </div>
          {VIEW_STRUCTURE(values).purchases.inputs.map((item) =>
            !item.hide ? (
              <div
                key={randomKey()}
                className={cn('row', styles.purchasesInput)}
              >
                <p>{item.label}</p>
                <input
                  value={item.hideValue ? '' : item.value}
                  disabled
                  className={cn(item.hideValue ? styles.placeholderInput : '')}
                />
                <input
                  value={item.hideAdj ? '' : item.adjustment}
                  disabled
                  className={cn(item.hideAdj ? styles.placeholderInput : '')}
                />

                <input
                  value={item.hideReason ? '' : item.reason}
                  disabled
                  className={cn(item.hideReason ? styles.placeholderInput : '')}
                />
                <input
                  className={cn(
                    isChanged(item.tax, item.newTax) ? styles.adjustedVat : '',
                    item.hideTax ? styles.placeholderInput : '',
                  )}
                  value={item.newTax || item.tax}
                  disabled
                />
              </div>
            ) : (
              <React.Fragment key={randomKey()} />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ViewReturnForm);
