import React from 'react';
import { MainLayout } from '../../../../components/MainLayout';
import { PagesHeader } from '../../../../components/shared/PagesHeader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { COLUMNS, ROUTES } from './PurchasesRegister.config';
import styles from './PurchasesRegister.styles.scss';
import { Filters } from '../../components/Filters';

const PurchasesRegister = ({ loading, invoices, getRegisters }) => {
  const { t } = useTranslation();

  return (
    <MainLayout eInvoicing={true}>
      <PagesHeader
        title={'VAT Purchase Register'}
        withBreadcrumb={true}
        routes={ROUTES}
        isTaxReturn={true}
      />
      <div className={styles.container}>
        <Filters getRegisters={getRegisters} />
        <DataTable value={invoices} className="" loading={loading}>
          {COLUMNS.map((column) => (
            <Column
              key={column.label}
              field={column.value}
              header={t(column.label)}
              sortable={column.sortable}
              body={column.body}
            />
          ))}
        </DataTable>
      </div>
    </MainLayout>
  );
};

export default React.memo(PurchasesRegister);
